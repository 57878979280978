import { useMemo } from "react";
import { findSearchConfigSection } from "src/search/config";

const useSearchFilterSections = (attributesToSkip, attributesToRender) => {
  const allSections = useSearchConfigSections(attributesToRender);

  const filteredSections = useSkipSections(allSections, attributesToSkip);

  const sectionsToHide = useHiddenSections(filteredSections);

  return { sections: filteredSections, sectionsToHide };
};

export default useSearchFilterSections;

// Based on attributesToRender, decide which search config sections can be displayed.
//
// For a given attribute, the config for how it's displayed is defined in the local search config.
// Then within algolia we define attributesToRender, which gives us which attributes to render, and in which order,
// so make sure we're using attributesToRender to set the order instead of the local search config
//
// @see https://www.algolia.com/doc/api-reference/widgets/dynamic-facets/react/#connector-param-provided-attributestorender
const useSearchConfigSections = (attributesToRender) => {
  return useMemo(
    () => attributesToRender.map(findSearchConfigSection).filter(Boolean),
    [attributesToRender]
  );
};

// attributesToSkip allows us to skip rendering filter sections based on the page.
// eg on explore/united-kingdom there's no point showing country or continent
const useSkipSections = (sectionsToRender, attributesToSkip) => {
  return useMemo(() => {
    // Special case to hide both
    if (attributesToSkip.includes("country")) {
      attributesToSkip.push("continent");
    }

    return sectionsToRender.filter(
      ({ filters }) =>
        !filters.some((filter) => attributesToSkip.includes(filter.attribute))
    );
  }, [sectionsToRender, attributesToSkip]);
};

// We can also hide some sections.
// These will still be rendered but hidden with css.
// This can be beneficial, since rendering new filters on the page can cause 'ScrollTo' to be called
const useHiddenSections = (sectionsToRender) => {
  return useMemo(() => {
    const attributesToHide = [];

    return sectionsToRender.reduce((acc, section, index) => {
      if (
        section.filters.some((filter) =>
          attributesToHide.includes(filter.attribute)
        )
      ) {
        acc.push(index);
      }
      return acc;
    }, []);
  }, [sectionsToRender]);
};

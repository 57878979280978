import { useCallback, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useIntersection } from "src/hooks/useIntersection";
import { getShortlistedProductIds } from "src/state/selectors";
import { setAlgoliaQueryContext } from "src/utils/algoliaQueryContext";
import {
  EVENT_NAME_CLICK_CAROUSEL_ITEM,
  EVENT_NAME_PRODUCT_CLICKED,
  EVENT_NAME_PRODUCT_LIST_VIEWED,
  trackAnalyticsEvent,
} from "src/utils/track";

import {
  hitToTrackingObject,
  hitsToTrackingObject,
} from "../../Search/tracking";

const formattedName = (name) => name.toLowerCase().replace(" ", "-");
const listId = (name) =>
  `carousel__${formattedName(name)}__${document.location.pathname}`;

export const useProductListViewTracking = ({ name, hits }) => {
  const ref = useRef();
  const inViewport = useIntersection(ref, "-200px");
  useEffect(() => {
    if (inViewport) {
      trackAnalyticsEvent(EVENT_NAME_PRODUCT_LIST_VIEWED, {
        list_id: listId(name),
        category: formattedName(name),

        ...hitsToTrackingObject(
          hits,
          process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX_CAROUSELS,
          "view"
        ),
      });
    }
  }, [inViewport, name, hits]);

  return { ref };
};

export const useHitTracking = (carouselIndex, name, hit) => {
  const shortlistProductIds = useSelector(getShortlistedProductIds);

  const trackingContext = useMemo(
    () => ({
      carouselIndex,
      list: listId(name),
      index: process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX_CAROUSELS,
      queryID: hit.__queryID,
      objectID: hit.objectID,
      position: hit.__position,
      isShortlisted: shortlistProductIds.includes(hit.productID),

      ...hitToTrackingObject(hit),
    }),
    [carouselIndex, hit, name, shortlistProductIds]
  );

  const handleClick = useCallback(() => {
    setAlgoliaQueryContext(
      trackingContext.index,
      trackingContext.queryID,
      trackingContext.objectID,
      shortlistProductIds.includes(trackingContext.productID)
    );

    trackAnalyticsEvent(EVENT_NAME_CLICK_CAROUSEL_ITEM, {
      ...trackingContext,
      type: "collection",
    });

    trackAnalyticsEvent(EVENT_NAME_PRODUCT_CLICKED, {
      ...trackingContext,
      eventType: "click",
    });
  }, [shortlistProductIds, trackingContext]);

  return { handleClick, trackingContext };
};

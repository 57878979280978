import { useCallback, useMemo } from "react";
import { connectRange } from "react-instantsearch-dom";
import * as date from "src/utils/date";

import { Select } from "@muchbetteradventures/styleguide";

import { trackRefineFilter } from "../../tracking";

const dayOfMonth = (date, monthIncrement = 0, day = 1) => {
  if (date) {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth() + monthIncrement,
        day,
        0,
        0,
        0,
        0
      )
    );
  } else {
    return undefined;
  }
};

const getMaxValueFromMonthSelected = (max, value) => {
  const dateValue = date.epochToDate(value);
  const lastDayOfMonthDate = dayOfMonth(dateValue, 1, 0);
  const lastDayOfMonthEpoch = date.dateToEpoch(lastDayOfMonthDate);

  return Math.min(max, lastDayOfMonthEpoch);
};

const getMinValueFromMonthSelected = (min, value) => {
  const dateValue = date.epochToDate(value);
  const firstDayOfMonthDate = dayOfMonth(dateValue);
  const firstDayOfMonthEpoch = date.dateToEpoch(firstDayOfMonthDate);

  return Math.max(min, firstDayOfMonthEpoch);
};

const DepartureMonthSelect = connectRange(
  ({
    attribute,
    currentRefinement,
    min,
    max,
    refine,
    icon,
    placeholder,
    colorVariant,
  }) => {
    // Setting it to 0 ensure it will appear at the top,
    const ANYTIME_SELECT_OPTION_VALUE = "0";

    // Creates monthly options for the select field
    // starting from the min datetime, then incrementing by a month at a time until it hits the max,
    // taking the earliest timestamp of the month as the value
    // eg
    // { 1728259200: {name: "October 2023" }}
    //
    // We also include an 'Anytime' special value
    const selectOptions = useMemo(() => {
      const monthOptions = {};
      monthOptions[ANYTIME_SELECT_OPTION_VALUE] = { name: "Anytime" };

      if (min && max) {
        const minAsDate = date.epochToDate(min);
        const maxAsDate = date.epochToDate(max);

        let counter = 0;
        let currentMonth = dayOfMonth(minAsDate, counter);
        while (currentMonth < maxAsDate) {
          const value = date.dateToEpoch(currentMonth);
          monthOptions[value] = {
            name: `${currentMonth.toLocaleString("default", {
              month: "long",
            })} ${currentMonth.getFullYear()}`,
          };

          counter++;
          currentMonth = dayOfMonth(minAsDate, counter);
        }
      }

      return monthOptions;
    }, [min, max]);

    const handleChange = useCallback(
      (value) => {
        let minValue;
        let maxValue;

        if (value === ANYTIME_SELECT_OPTION_VALUE) {
          // If they selected anytime, then reset the filters to the absolute min/max of the range
          minValue = min;
          maxValue = max;
        } else {
          // Otherwise make sure that the min max refinement values
          // align with the start and end of the month

          minValue = value ? getMinValueFromMonthSelected(min, value) : "";
          maxValue = value ? getMaxValueFromMonthSelected(max, value) : "";
        }

        refine({ min: minValue, max: maxValue });
        trackRefineFilter(attribute, { min: minValue, max: maxValue });
      },
      [attribute, max, min, refine]
    );

    let value;
    if (currentRefinement.min === min && currentRefinement.max === max) {
      // The the refinement absolute values are equal to the min/max of the range
      // then the filter has been reset
      // so we should reset the select field to the placeholder
      value = "";
    } else {
      // Otherwise create the value for the select
      // as per the creation of the select options - taking the earliest timestamp of the selected month
      const currentRefinementMinAsDate = date.epochToDate(
        currentRefinement.min
      );
      let currentRefinementCurrentMonth = dayOfMonth(
        currentRefinementMinAsDate
      );
      value = currentRefinementCurrentMonth
        ? date.dateToEpoch(currentRefinementCurrentMonth)
        : "";
    }

    return (
      <Select
        colorVariant={colorVariant}
        icon={icon}
        onChange={handleChange}
        options={selectOptions}
        placeholder={placeholder}
        value={value}
      />
    );
  }
);

export default DepartureMonthSelect;

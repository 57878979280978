import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  tabPanelsContainer: {
    display: "grid",
    gridTemplateRows: "100%",
    gridTemplateColumns: "auto",
  },
  // All tab panels use the same grid area.
  // This means they stack on top of each other,
  // and so the fading them in and out creates the dissolve effect
  tabPanel: {
    gridArea: "1 / 1 / 2 / 2",
  },
}));

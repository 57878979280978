import clsx from "clsx";
import React from "react";
import useSwiperRef from "src/hooks/useSwiperRef";
import { A11y, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import {
  IconButton,
  createStyles,
  isWidthDown,
  isWidthUp,
  makeStyles,
  withWidth,
} from "@material-ui/core";
import { Box, Grid } from "@material-ui/core";
import {
  ChevronLeft,
  ChevronRight,
} from "@muchbetteradventures/components/dist/Icons";

import Row from "../shared/Row";
import SalesPoint from "./SalesPoint";

const useStyles = makeStyles((theme) => {
  return createStyles({
    staticGrid: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      maxWidth: 950,
      margin: "0 auto",
    },
    swiper: {
      width: "100%",
      height: "100%",

      "&.swiper-horizontal": {
        [theme.breakpoints.up("md")]: {
          marginLeft: theme.spacing(10),
          marginRight: theme.spacing(10),
          width: "auto",
        },
      },
    },
    swiperSlide: {
      height: "auto",
      marginBottom: theme.spacing(4), // move image away from pagination bullets
    },
    swiperSlideHorizontal: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    activeBullet: {
      background: "#E76135",
      opacity: 1,
    },
    navigationButtonContainer: {
      position: "absolute",
      top: 0,
      height: "100%",
      width: "100%",
      zIndex: 2,

      "&.swiper-button-disabled": {
        display: "none",
      },
    },
    navigationButton: {
      color: "black",
      position: "absolute",
      opacity: 0.5,
      zIndex: 999,
      top: "50%",
      transform: "translate(0%, -50%)",
      transition: "opacity 0.1s ease-in-out",
      "&:hover": {
        background: "none",
        opacity: "0.7 !important",
      },
    },
    navigationPrevButton: {
      left: theme.spacing(2),
    },
    navigationNextButton: {
      right: theme.spacing(2),
    },
  });
});

const SalesPoints = ({
  salesPoints = [],
  orientation = "Vertical",
  footer = false,
  width,
}) => {
  const classes = useStyles();

  const [swiper, setSwiper] = React.useState(null);
  const [prevEl, prevRef] = useSwiperRef();
  const [nextEl, nextRef] = useSwiperRef();

  React.useEffect(() => {
    if (swiper) {
      swiper.update();
      swiper.navigation?.update();
      swiper.pagination?.update();
    }
  }, [salesPoints, orientation, swiper]);

  const slidesPerView =
    isWidthDown("xs", width) ||
    (isWidthDown("md", width) && orientation === "Horizontal")
      ? 1
      : 2;

  return (
    <Row
      overflow="hidden"
      {...(footer ? { variant: "white", disableVerticalGutter: true } : {})}
    >
      {isWidthUp("md", width) && orientation === "Vertical" ? (
        <Grid className={classes.staticGrid} container spacing={5}>
          {salesPoints.slice(0, 4).map((salesPoint) => (
            <Grid key={salesPoint.title} item xs>
              <SalesPoint salesPoint={salesPoint} orientation={orientation} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box position="relative">
          <Swiper
            onSwiper={setSwiper}
            className={classes.swiper}
            allowTouchMove
            modules={[Pagination, Navigation, A11y]}
            slidesPerView={slidesPerView}
            slidesPerGroup={slidesPerView}
            pagination={{
              clickable: false,
              bulletActiveClass: classes.activeBullet,
            }}
            navigation={{ prevEl, nextEl }}
          >
            {salesPoints.map((salesPoint, idx) => (
              <SwiperSlide
                key={idx}
                className={clsx(classes.swiperSlide, {
                  [classes.swiperSlideHorizontal]: orientation === "Horizontal",
                })}
              >
                <SalesPoint
                  salesPoint={salesPoint}
                  orientation={width === "xs" ? "Vertical" : orientation}
                />
              </SwiperSlide>
            ))}
          </Swiper>

          <Box
            className={classes.navigationButtonContainer}
            display={isWidthUp("md", width) ? "inherit" : "none"}
          >
            <IconButton
              ref={prevRef}
              disableRipple
              className={clsx(
                classes.navigationButton,
                classes.navigationPrevButton
              )}
            >
              <ChevronLeft fontSize="inherit" />
            </IconButton>
            <IconButton
              ref={nextRef}
              disableRipple
              className={clsx(
                classes.navigationButton,
                classes.navigationNextButton
              )}
            >
              <ChevronRight fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
      )}
    </Row>
  );
};

export default withWidth()(SalesPoints);

import clsx from "clsx";
import React from "react";

import { Button, createStyles, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      borderRadius: 100,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      boxShadow: theme.shadows[1],
      "&:hover": {
        boxShadow: theme.shadows[3],
      },
    },
    dark: {
      backgroundColor: grey[900],
      color: grey[100],
      "&:hover": {
        color: "white",
        backgroundColor: grey[700],
      },
    },
    light: {
      backgroundColor: "white",
      color: grey[900],
      "&:hover": {
        color: "black",
        backgroundColor: grey[100],
      },
    },
  })
);

const PillButton = ({ color = "dark", ...rest }) => {
  const classes = useStyles();
  return <Button className={clsx([classes.root, classes[color]])} {...rest} />;
};

export default PillButton;

import { Typography } from "@material-ui/core";
import { memo } from "react";

import { DisplayOnScreenSize } from "src/components/shared";

import { useStyles } from "./styles";

const TabLabel = ({ text }) => {
  const classes = useStyles();

  return (
    <DisplayOnScreenSize hideDown="xs">
      <Typography variant="h4" className={classes.label}>
        {text}
      </Typography>
    </DisplayOnScreenSize>
  );
};

export default memo(TabLabel);

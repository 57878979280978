import algoliasearch from "algoliasearch/lite";
import { memo } from "react";
import { Configure, InstantSearch, connectHits } from "react-instantsearch-dom";
import { filtersToStringWithMinStartDate } from "src/search/helpers";

import FullWidthCarousel from "../shared/FullWidthCarousel";
import Hit from "./Hit";
import { useStyles } from "./styles";
import { useProductListViewTracking } from "./tracking";

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_PUBLIC_API_KEY
);

const HitsCarousel = ({ name, hits, ...rest }) => {
  const styles = useStyles();

  const { ref } = useProductListViewTracking({ name, hits });

  return (
    <div ref={ref}>
      <FullWidthCarousel
        slides={hits.map((hit, index) => (
          <Hit key={hit.objectID} hit={hit} name={name} index={index} />
        ))}
        slideClassName={styles.slide}
        name={name}
        {...rest}
      />
    </div>
  );
};

const ConnectedHitsCarousel = connectHits(HitsCarousel);

const CollectionCarousel = ({ name, ...rest }) => (
  <InstantSearch
    searchClient={searchClient}
    indexName={process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX_CAROUSELS}
  >
    <Configure
      filters={filtersToStringWithMinStartDate({ collection: name })}
      clickAnalytics
    />
    <ConnectedHitsCarousel name={name} {...rest} />
  </InstantSearch>
);

export default memo(CollectionCarousel);

import {
  EVENT_NAME_EXPLORE_PAGE_TAB_CHANGE,
  trackAnalyticsEvent,
} from "src/utils/track";

export const trackTabChange = (tab) => {
  const { data, ...tabInfo } = tab; // eslint-disable-line

  trackAnalyticsEvent(EVENT_NAME_EXPLORE_PAGE_TAB_CHANGE, tabInfo);
};

import clsx from "clsx";
import { useCallback } from "react";

import Gutter from "src/context/gutter/Gutter";
import Container from "../Container";
import LinkCard from "../LinkCard";
import Promotion from "../Promotion";
import Row from "../shared/Row";

import { useStyles } from "./styles";
import { trackLinkItemClick, trackPromoClick } from "./tracking";

const GridRow = ({ promo = null, collections = [] }) => {
  const classes = useStyles();

  const makeHandlePromoClick = useCallback(
    (promo) => () => trackPromoClick(promo),
    []
  );

  const makeHandleLinkCardClick = useCallback(
    (page) => () => trackLinkItemClick(page),
    []
  );

  const hasPromo = Boolean(promo);
  return (
    <Row
      variant="light"
      className={clsx({
        [classes.disableTopGutterMobile]: hasPromo,
        [classes.disableBottomGutterMobile]: !collections.length,
      })}
    >
      <Container>
        <Gutter paddingIfNoGutter>
          <div
            className={clsx(
              classes.gridRoot,
              classes[`gridRoot${collections.length + (hasPromo ? 1 : 0)}`]
            )}
          >
            {promo && (
              <div className={clsx(classes.gridItem, classes.gridItem0)}>
                <Promotion
                  item={promo}
                  onCTAClick={makeHandlePromoClick(promo)}
                />
              </div>
            )}
            {collections.map((page, idx) => (
              <div
                key={idx}
                className={clsx(
                  classes.gridItem,
                  classes[`gridItem${idx + (hasPromo ? 1 : 0)}`]
                )}
              >
                <LinkCard
                  page={page}
                  href={`/explore/${page.slug}/`}
                  onClick={makeHandleLinkCardClick(page)}
                />
              </div>
            ))}
          </div>
        </Gutter>
      </Container>
    </Row>
  );
};

export default GridRow;

import { memo } from "react";

import ProductCard from "src/components/ProductCard";
import ProductHitTrackingContext from "src/context/tracking/productHit";

import { useTheme } from "@material-ui/core";
import { makeImageSizes } from "./styles";

import { useHitTracking } from "./tracking";

const coerceToArray = (value) => (Array.isArray(value) ? value : [value]);

const Hit = ({ index, name, hit, ...rest }) => {
  const theme = useTheme();

  const { trackingContext, handleClick } = useHitTracking(index, name, hit);

  return (
    <ProductHitTrackingContext.Provider value={trackingContext}>
      <ProductCard
        productId={hit.productID}
        title={hit.name}
        reviewCount={hit.reviewCount}
        reviewScore={hit.reviewScore}
        countries={hit.country}
        durations={coerceToArray(hit.duration)}
        images={hit.images}
        imageSizes={makeImageSizes(theme)}
        url={hit.url}
        onClick={handleClick}
        currency={hit.currency}
        price={parseInt(hit.price)}
        showShortlistButton
        {...rest}
      />
    </ProductHitTrackingContext.Provider>
  );
};

export default memo(Hit);

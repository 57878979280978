import React from "react";

import TextButton from "../shared/TextButton";

const ShowMoreButton = ({ showingMore, ...rest }) => {
  return (
    <TextButton {...rest}>
      {showingMore ? "Show less" : "Show more..."}
    </TextButton>
  );
};

export default ShowMoreButton;

import { makeStyles } from "@material-ui/core";

import { makeSlideWidthHeight } from "../shared/FullWidthCarousel";

export const useStyles = makeStyles((theme) => ({
  slide: {
    ...makeSlideWidthHeight(theme, 1.1, { absoluteHeight: "auto" }),
    [theme.breakpoints.up(350)]: {
      ...makeSlideWidthHeight(theme, 1.25, { absoluteHeight: "auto" }),
    },
    [theme.breakpoints.up("sm")]: {
      ...makeSlideWidthHeight(theme, 2.15, { absoluteHeight: "auto" }),
    },
    [theme.breakpoints.up("md")]: {
      ...makeSlideWidthHeight(theme, 2, { absoluteHeight: "auto" }),
    },
    [theme.breakpoints.up(1024)]: {
      ...makeSlideWidthHeight(theme, 3, { absoluteHeight: "auto" }),
    },
    [theme.breakpoints.up(1600)]: {
      ...makeSlideWidthHeight(theme, 4, { absoluteHeight: "auto" }),
    },
    [theme.breakpoints.only("xl")]: {
      ...makeSlideWidthHeight(theme, 5, { absoluteHeight: "auto" }),
    },
  },
  cardRoot: {
    backgroundColor: "white",
    border: 0,
    borderRadius: theme.spacing(2),
    overflow: "hidden",
    height: "100%",
  },
  cardActionArea: {
    height: "100%",
  },
  imageContainer: {
    position: "relative",
    height: 0,
    width: "100%",
    paddingTop: `${100 / 1.4}%`,
  },
  image: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
  },
}));

export const makeImageSizes = (theme) => `
  (max-width: 350px) 90vw,
  (max-width: ${theme.breakpoints.values["sm"]}px) 80vw,
  (max-width: ${theme.breakpoints.values["md"]}px) 45vw,
  (max-width: 1024px) 50vw,
  (max-width: 1600px) 35vw,
  (max-width: ${theme.breakpoints.values["xl"]}px) 25vw,
  20vw
`;

import { SEARCH_MODE_VALUE_DEPARTURES } from "./searchMode";

export const getIndexNameFromSearchModeAndFilters = (
  searchMode,
  filters,
  departuresSearchModeValue = SEARCH_MODE_VALUE_DEPARTURES,
  multiActivityFilterValue = "Multi Activity",
  productsIndex = process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX,
  productsActivityCountAscIndex = `${process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX}_activitycount_asc`,
  departuresIndex = process.env.NEXT_PUBLIC_ALGOLIA_DEPARTURES_INDEX,
  departuresActivityCountAscIndex = `${process.env.NEXT_PUBLIC_ALGOLIA_DEPARTURES_INDEX}_activitycount_asc`
) => {
  const isDeparturesMode = searchMode === departuresSearchModeValue;
  const filterIsSelectedButNotMultiActivity =
    filters.activity && filters.activity !== multiActivityFilterValue;

  if (isDeparturesMode) {
    if (filterIsSelectedButNotMultiActivity)
      return departuresActivityCountAscIndex;
    return departuresIndex;
  }

  if (filterIsSelectedButNotMultiActivity) return productsActivityCountAscIndex;
  return productsIndex;
};

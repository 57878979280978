import { memo } from "react";

import * as Icons from "@muchbetteradventures/components/dist/Icons";

import { useStyles } from "./styles";

const TabIcon = ({ iconName }) => {
  const classes = useStyles();

  const IconComponent = Icons[iconName];

  return <IconComponent className={classes.icon} />;
};

export default memo(TabIcon);

import { memo, useCallback } from "react";

import { Box, Typography } from "@material-ui/core";
import Highlight from "src/components/ProductPage/Highlight";
import { DisplayOnScreenSize } from "src/components/shared";
import Row from "src/components/explore/shared/Row";
import DesktopSwiper from "./DesktopSwiper";
import MobileSwiper from "./MobileSwiper";

import { useStyles } from "./styles";
import { trackSlideChange } from "./tracking";

const FullWidthCarousel = ({
  title = null,
  icon = null,
  subtitle = null,
  slides,
  disableVerticalGutter,
  rowVariant,
  // Use this to override slide widths/heights. Carousel groups and pagination will auto recalculate
  slideClassName,
}) => {
  const classes = useStyles();

  const handleSlideChange = useCallback(
    (swiper) => {
      trackSlideChange(swiper, title);
    },
    [title]
  );

  return (
    <Row disableVerticalGutter={disableVerticalGutter} variant={rowVariant}>
      {(title || subtitle) && (
        <Box position="relative" className={classes.title} marginBottom={2}>
          {title && (
            <Highlight
              label={title}
              labelVariant="h3"
              valueVariant="body2"
              icon={icon}
            />
          )}

          {subtitle && (
            <Typography color="textSecondary" variant="body2" gutterBottom>
              {subtitle}
            </Typography>
          )}
        </Box>
      )}

      <DisplayOnScreenSize hideUp={"md"}>
        <MobileSwiper
          slides={slides}
          slideClassName={slideClassName}
          onSlideChange={handleSlideChange}
        />
      </DisplayOnScreenSize>
      <DisplayOnScreenSize hideDown={"sm"}>
        <DesktopSwiper
          slides={slides}
          slideClassName={slideClassName}
          onSlideChange={handleSlideChange}
        />
      </DisplayOnScreenSize>
    </Row>
  );
};

export default memo(FullWidthCarousel);

import { memo, useState, useCallback } from "react";

import { TabNavigation, TabPanels } from "./components";

import { useStyles } from "./styles";

const TabsDesktop = ({ tabsData }) => {
  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = useCallback((e, tabIndex) => {
    setTabIndex(tabIndex);
  }, []);

  return (
    <div className={classes.root}>
      <TabNavigation
        currentTabIndex={tabIndex}
        onChange={handleTabChange}
        tabsData={tabsData}
      />

      <TabPanels currentTabIndex={tabIndex} tabsData={tabsData} />
    </div>
  );
};

export default memo(TabsDesktop);

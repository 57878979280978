import isEqual from "lodash.isequal";
import React, { memo } from "react";
import { connectDynamicWidgets } from "react-instantsearch-dom";
import Accordion from "src/components/Accordion";
import {
  FEATURE_FLAG_EXPLORE_PAGE_WWW,
  TEST,
  useFeatureFlagContext,
} from "src/context/featureFlags";

import { useTheme } from "@material-ui/core";

import { useSearchFilterSections } from "./hooks";
import makeFilterAccordionPanels from "./makeFilterAccordionPanels";

const SearchFilters = ({ pageLevelFilters, attributesToRender }) => {
  const theme = useTheme();
  const styleOverrides = {
    panelToggle: { padding: theme.spacing(2), borderBottomWidth: "1px" },
  };

  const { getFeatureFlag } = useFeatureFlagContext();
  const enableWWW = getFeatureFlag(FEATURE_FLAG_EXPLORE_PAGE_WWW) === TEST;

  const pageLevelFilteredAttributes = Object.keys(pageLevelFilters);
  const featureFlagAttributesToSkip = enableWWW
    ? ["continent", "activity", "startDate"]
    : [];

  const attributesToSkip = [
    ...pageLevelFilteredAttributes,
    ...featureFlagAttributesToSkip,
  ];

  const { sections, sectionsToHide } = useSearchFilterSections(
    attributesToSkip,
    attributesToRender
  );

  return (
    <Accordion
      panelContentProps={{ padding: 2, paddingTop: 0 }}
      panels={makeFilterAccordionPanels(sections, styleOverrides)}
      initialExpandedPanels={[]}
      shouldOpenSinglePanel
      hiddenPanels={sectionsToHide}
    />
  );
};

export default connectDynamicWidgets(memo(SearchFilters, isEqual));

import { memo } from "react";

import { Box } from "@material-ui/core";

import useGutter from "./use";

const Gutter = ({
  paddingIfGutter = false,
  paddingIfNoGutter = false,
  marginIfGutter = false,
  marginIfNoGutter = false,
  children,
}) => {
  const { hasGutter, gutterSize } = useGutter();

  const marginX = () => {
    if (marginIfGutter && hasGutter) return gutterSize;
    if (marginIfNoGutter && !hasGutter) return gutterSize;
    return 0;
  };

  const paddingX = () => {
    if (paddingIfGutter && hasGutter) return gutterSize;
    if (paddingIfNoGutter && !hasGutter) return gutterSize;
    return 0;
  };

  return (
    <Box marginX={marginX()} paddingX={paddingX()}>
      {children}
    </Box>
  );
};

export default memo(Gutter);

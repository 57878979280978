import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",

    height: "490px",
    paddingBottom: "30px",

    color: theme.palette.common.white,

    background:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0.09%, rgba(0, 0, 0, 0.15) 100.09%)",

    [theme.breakpoints.up("sm")]: {
      paddingBottom: "40px",
    },

    [theme.breakpoints.up("md")]: {
      paddingBottom: "50px",
    },
  },
  noBgImage: {
    height: "auto",
    paddingTop: "100px",

    backgroundImage: "url('/images/_next/illustrations-bg-black.png')",
  },
  bgImage: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: "-1",
  },
  title: {
    marginTop: "25px",
  },
  subtitle: {
    marginTop: "25px",
  },
  intro: {
    marginTop: "25px",
  },
}));

// On mobile screen sizes, since the height is fixed,
// we end up with a portrait image space.
// The images they upload for the hero will usually be landscape
// so we'll only actually display ~50% of the full image width.
// We need to fetch a wider image to avoid poor quality
export const makeImageSizes = (theme) => `
  (max-width: ${theme.breakpoints.values["sm"]}px) 200vw,
  100vw
`;

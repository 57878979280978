import React from "react";

import { Box, CircularProgress } from "@material-ui/core";

const Loader = () => (
  <Box height="70vh" display="flex" alignItems="center" justifyContent="center">
    <CircularProgress />
  </Box>
);

export default Loader;

import { memo } from "react";
import { MagazineArticleCarousel } from "src/components/explore/carousels";

const MagazineRow = ({ rows }) => {
  const magazineRow = rows.find(
    (element) =>
      element.type === "MagazineArticleCarousel" && element.value.length >= 3
  );
  if (magazineRow) {
    return (
      <MagazineArticleCarousel
        magazineArticles={magazineRow.value}
        rowVariant="invertedGradient"
        {...magazineRow}
        title="Need some inspiration?"
      />
    );
  }
  return null;
};

export default memo(MagazineRow);

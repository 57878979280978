import { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { getAccommodationCategories } from "src/state/selectors";

import RefinementList from "./RefinementList";

const AccommodationCategoriesRefinementList = (props) => {
  const accommodationCategories = useSelector(getAccommodationCategories);

  const descriptions = useMemo(
    () =>
      accommodationCategories.reduce((acc, entry) => {
        acc[entry.title] = entry.description;
        return acc;
      }, {}),
    [accommodationCategories]
  );

  return <RefinementList {...props} descriptions={descriptions} />;
};

export default memo(AccommodationCategoriesRefinementList);

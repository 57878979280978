import { useMemo } from "react";

export const useTabsData = (
  childAttributes = [],
  collections = [],
  childPages = [],
  collectionPages = []
) => {
  return useMemo(() => {
    const tabNames = getTabNames(childAttributes, collections);

    return tabNames.reduce((tabsData, tabName) => {
      const tabData = makeTabData(
        tabName,
        collections,
        collectionPages,
        childPages
      );

      if (isTabValid(tabData)) return [...tabsData, tabData];
      else {
        console.warn("Tab will not rendered, invalid data: ", tabData);
        return tabsData;
      }
    }, []);
  }, [childAttributes, collections, childPages, collectionPages]);
};

const getTabNames = (childAttributes, collections = []) => {
  if (collections.length) return [...childAttributes, "collections"];
  return childAttributes;
};

const makeTabData = (tabName, collections, collectionPages, childPages) => {
  let tabData = {
    name: tabName,
    iconName: getTabIconName(tabName),
    displayName: getTabDisplayName(tabName),
    data: [],
  };

  if (tabName === "collections") {
    tabData.data = makeCollectionsTabData(collections, collectionPages);
  } else {
    tabData.data = makeAttributeTabData(tabName, childPages);
  }

  return tabData;
};

const makeAttributeTabData = (attribute, childPages) =>
  childPages.filter((page) => page.attribute === attribute);

const makeCollectionsTabData = (collections, collectionPages) => {
  return collections
    .map((collection) =>
      collectionPages.find((page) => page.value === collection.name)
    )
    .filter((collectionPage) => !!collectionPage);
};

const getTabIconName = (tabName) => {
  switch (tabName) {
    case "collections":
      return "Lightbulb";

    case "activity":
      return "Leap";

    case "continent":
      return "Earth";

    case "country":
      return "MapPin";

    default:
      return "Lightning";
  }
};

const getTabDisplayName = (tabName) => {
  switch (tabName) {
    case "collections":
      return "Collections";

    case "activity":
      return "Activities";

    case "country":
      return "Countries";

    case "continent":
      return "Continents";
  }
};

const isTabValid = (tabData) => {
  return (
    tabData.name &&
    tabData.iconName &&
    tabData.displayName &&
    tabData.data &&
    tabData.data.length >= 4
  );
};

import dynamic from "next/dynamic";
import { memo } from "react";
import {
  FEATURE_FLAG_EXPLORE_PAGE_WWW,
  TEST,
  useFeatureFlagContext,
} from "src/context/featureFlags";
import GutterProvider from "src/context/gutter/provider";
import explorePageIndexingStrategy from "src/seo/indexingStrategies/explorePageIndexingStrategy";

import SEO from "../seo";
import Hero from "./Hero";
import MagazineRow from "./MagazineRow";
import PageRows from "./PageRows";
import Loader from "./Search/components/Loader";
import Tabs from "./Tabs";
import { useLoadingState } from "./hooks";

const Search = dynamic(() => import("./Search"), {
  loading: Loader,
});

const ExplorePage = ({
  isSSR,
  pagePath,
  isRootExplorePage,
  explorePageData,
  currentPath,
  childAttributes,
  childPages,
  collectionPages,
  initialSearchProps = {},
  seoMetadata,
}) => {
  const isReady = useLoadingState();

  const { getFeatureFlag } = useFeatureFlagContext();
  const enableWWW = getFeatureFlag(FEATURE_FLAG_EXPLORE_PAGE_WWW) === TEST;

  const showCarousels = isSSR || !enableWWW;

  const defaultTitle = currentPath
    .map((item) => item.value)
    .slice(0, 2)
    .reverse()
    .join(" in ");

  const heroImageSrc =
    explorePageData.heroImage && explorePageData.heroImage.url;

  return (
    <>
      <SEO
        title={seoMetadata.title}
        description={seoMetadata.description}
        metas={seoMetadata.metas}
        links={seoMetadata.links}
        indexingStrategy={explorePageIndexingStrategy}
        pagePath={pagePath}
      />

      {isReady ? (
        <>
          <GutterProvider>
            <>
              {explorePageData.showHero && (
                <Hero
                  showBreadcrumbs={explorePageData.showBreadcrumbs}
                  currentPath={currentPath}
                  imageSrc={heroImageSrc}
                  title={explorePageData.heroTitle || defaultTitle}
                  subtitle={explorePageData.heroSubtitle}
                  intro={explorePageData.intro}
                />
              )}

              {showCarousels && (
                <Tabs
                  childAttributes={childAttributes}
                  collections={explorePageData.collections}
                  childPages={childPages}
                  collectionPages={collectionPages}
                />
              )}
            </>

            {!explorePageData.showSearch && (
              <PageRows
                rows={explorePageData.rows}
                currentPath={currentPath}
                childPages={childPages}
                collectionPages={collectionPages}
              />
            )}

            {explorePageData.showSearch && (
              <>
                <Search
                  {...initialSearchProps}
                  isSSR={isSSR}
                  isRootExplorePage={isRootExplorePage}
                />
                <>
                  {/* TODO: Reimplement this component properly */}
                  <MagazineRow rows={explorePageData.rows} />
                </>
              </>
            )}
          </GutterProvider>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default memo(ExplorePage);

import { memo, useCallback } from "react";
import {
  EVENT_NAME_CLICK_CAROUSEL_ITEM,
  trackAnalyticsEvent,
} from "src/utils/track";

import { useTheme } from "@material-ui/core";

import LinkCard from "../../LinkCard";
import FullWidthCarousel, {
  makeDefaultImageSizes,
} from "../shared/FullWidthCarousel";

const AttributeCarousel = ({ pages = [], minItems = 3, ...rest }) => {
  const theme = useTheme();

  const totalHits = pages.reduce((total, { hits }) => (total += hits), 0);

  const makeHandleLinkCardClick = useCallback(
    (index, page) => () => {
      trackAnalyticsEvent(EVENT_NAME_CLICK_CAROUSEL_ITEM, {
        carouselIndex: index,
        type: page.attribute,
        carouselItem: page,
      });
    },
    []
  );

  return totalHits > minItems && pages.length >= minItems ? (
    <FullWidthCarousel
      slides={pages.map((page, index) => (
        <LinkCard
          key={page.url}
          page={page}
          href={page.url}
          onClick={makeHandleLinkCardClick(index, page)}
          imageSizes={makeDefaultImageSizes(theme)}
        />
      ))}
      {...rest}
    />
  ) : null;
};

export default memo(AttributeCarousel);

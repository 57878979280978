export const getSearchProps = async (
  searchConfigProvider,
  pageLevelFiltersProvider,
  searchModeProvider,
  searchStateProvider
) => {
  const searchConfig = await searchConfigProvider();

  const pageLevelFilters = await pageLevelFiltersProvider();

  const searchMode = await searchModeProvider();

  const searchStateRaw = await searchStateProvider(searchConfig);
  const searchState = JSON.parse(JSON.stringify(searchStateRaw));

  return {
    searchMode,
    pageLevelFilters,
    searchState,
  };
};

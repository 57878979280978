import { useState, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";

const useResize = (ref) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const debouncedHandleResize = useDebouncedCallback(
    () => {
      if (ref && ref.current) {
        setWidth(ref.current.offsetWidth);
        setHeight(ref.current.offsetHeight);
      }
    },
    100,
    {
      maxWait: 2000,
    }
  );

  useEffect(() => {
    window.addEventListener("resize", debouncedHandleResize);
    debouncedHandleResize();

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, [ref, debouncedHandleResize]);

  return { width, height };
};

export default useResize;

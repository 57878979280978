import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { useEffect, useState, memo } from "react";
import { A11y, FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import clsx from "clsx";

import { useStyles } from "./styles";

const MobileSwiper = ({ slides, slideClassName, onSlideChange }) => {
  const classes = useStyles();
  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    if (swiper) {
      swiper.update();
    }
  }, [slides, swiper]);

  return (
    <Swiper
      onSwiper={setSwiper}
      modules={[FreeMode, A11y]}
      allowTouchMove={true}
      freeMode={{ enabled: true }}
      slidesPerView="auto"
      onSlideChange={onSlideChange}
      className={classes.swiper}
    >
      {slides.map((slide, idx) => (
        <SwiperSlide
          key={idx}
          className={clsx(classes.slideBase, slideClassName)}
        >
          {() => slide}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default memo(MobileSwiper);

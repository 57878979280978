import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  description: {
    marginBottom: theme.spacing(1),
    marginLeft: "35px",
    lineHeight: "16px",
  },
  labelDisabled: {
    color: "#CACACA",
  },
  labelCount: {
    marginLeft: 8,
    letterSpacing: 1,
  },
}));

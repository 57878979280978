import React from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import * as Icons from "@muchbetteradventures/components/dist/Icons";

const InformationDialog = ({ title, content, open, handleClose }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      fullScreen={fullScreen}
    >
      <DialogTitle id="alert-dialog-title" disableTypography>
        <Typography variant="h4">{title}</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          {content.map((item) => (
            <Grid key={item.heading} item xs={12}>
              <Typography>
                <strong>{item.heading}</strong>
              </Typography>
              {item.contentBlocks?.map((contentBlock) => {
                const Icon = contentBlock.icon
                  ? Icons[contentBlock.icon]
                  : null;
                return (
                  <Box key={contentBlock.body} display="flex" my={1}>
                    <Box mr={2}>{Icon && <Icon />}</Box>
                    <Typography>{contentBlock.body}</Typography>
                  </Box>
                );
              })}
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InformationDialog;

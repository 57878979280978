import { memo } from "react";

import { TRANSITION_SPEED } from "src/components/explore/Tabs/constants";

import { Fade } from "@material-ui/core";

import { useStyles } from "./styles";

/*

Reason for splitting these up:

"To better support server rendering Material-UI provides a style property to the children of some transition components (Fade, Grow, Zoom, Slide).
The style property must be applied to the DOM for the animation to work as expected."

@see https://v4.mui.com/components/transitions/

*/
const TabPanel = ({ children, index, ...props }) => {
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      id={`a11y-tabpanel-${index}`}
      aria-labelledby={`a11y-tab-${index}`}
      className={classes.tabPanel}
      {...props}
    >
      {children}
    </div>
  );
};

const TabPanelFade = ({ index, currentIndex, children }) => {
  return (
    <Fade in={index === currentIndex} timeout={TRANSITION_SPEED}>
      <TabPanel index={index}>{children}</TabPanel>
    </Fade>
  );
};

export default memo(TabPanelFade);

import { memo } from "react";

import { StyledClearAllChip } from "./styles";

const ClearAllChip = ({ refine, label = "Clear All" }) => {
  return (
    <StyledClearAllChip
      clickable
      color="primary"
      variant="outlined"
      label={label}
      onClick={(e) => {
        e.stopPropagation();
        refine([]);
      }}
    />
  );
};

export default memo(ClearAllChip);

import { makeStyles, withStyles, Tabs, Tab } from "@material-ui/core";

import { TRANSITION_SPEED } from "src/components/explore/Tabs/constants";

const BORDER_RADIUS = "40px";
const INDICATOR_Z_INDEX = 1;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",

    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
  },
}));

export const StyledMuiTabs = withStyles((theme) => ({
  root: {
    borderRadius: BORDER_RADIUS,
    padding: "5px;",

    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
  },
  indicator: {
    zIndex: INDICATOR_Z_INDEX,

    height: "100%",
    borderRadius: BORDER_RADIUS,

    backgroundColor: theme.palette.primary.main,

    transitionDuration: `${TRANSITION_SPEED}ms`,
  },
}))((props) => <Tabs {...props} variant="fullWidth" />);

export const StyledMuiTab = withStyles((theme) => ({
  root: {
    borderRadius: BORDER_RADIUS,
    minWidth: "80px",
    minHeight: "44px",
    maxHeight: "44px",

    color: theme.palette.common.black,
    transition: `color ${TRANSITION_SPEED}ms`,

    [theme.breakpoints.up("sm")]: {
      padding: "10px 25px",
    },

    "&:hover": {
      backgroundColor: "#F4F4F4",
    },
  },
  wrapper: {
    zIndex: INDICATOR_Z_INDEX + 1, // keep button text above indicator

    flexDirection: "row",
  },
  selected: {
    color: theme.palette.common.white,
  },
}))((props) => <Tab {...props} disableRipple disableFocusRipple />);

//
// Single Tab Special Cases
// --------
//

export const StyledMuiTabsSingleTab = withStyles(() => ({
  indicator: {
    display: "none",
  },
}))(StyledMuiTabs);

export const StyledMuiTabSingleTab = withStyles((theme) => ({
  root: {
    backgroundColor: "#F4F4F4",

    "&:hover": {
      cursor: "auto",
    },
  },
  selected: {
    color: theme.palette.common.black,
  },
}))(StyledMuiTab);

import { useCallback } from "react";
import clsx from "clsx";

import Image, { Photograph } from "src/components/Image";
import PillButton from "src/components/PillButton";
import { Box, Typography } from "@material-ui/core";

import { trackClick, useTrackView } from "./tracking";
import { useStyles } from "./styles";

const Promotion = ({ item, onCTAClick }) => {
  const classes = useStyles();

  const ref = useTrackView(item);

  const handlePromoClick = useCallback(() => {
    if (onCTAClick) onCTAClick();

    trackClick(item);
  }, [onCTAClick, item]);

  return (
    <>
      {item.imageStyle === "Background" && (
        <Photograph src={item.image.url} alt="" sizes="95vw" />
      )}
      <div
        ref={ref}
        className={clsx(
          classes.imageOverlay,
          item.variant === "dark" ? classes.textDark : classes.textLight,
          item.imageStyle === "Background" &&
            (item.variant === "dark"
              ? classes.imageOverlayDark
              : classes.imageOverlayLight)
        )}
        {...(item.backgroundColor
          ? { style: { backgroundColor: item.backgroundColor } }
          : {})}
      >
        {item.imageStyle === "Icon" && (
          <Box className={classes.imageContainer}>
            <Image
              src={item.image.url}
              alt=""
              layout="fill"
              objectFit="contain"
            />
          </Box>
        )}
        <div>
          <Typography
            variant="overline"
            className={classes.overline}
            gutterBottom
          >
            {item.overline}
          </Typography>
          <Typography
            component="div"
            variant="h2"
            gutterBottom
            className={classes.title}
          >
            {item.title}
          </Typography>
          <Typography variant="body2" className={classes.subtitle}>
            {item.subtitle}
          </Typography>
        </div>
        <Box mt={2}>
          <PillButton
            color={item.variant}
            href={item.ctaUrl}
            onClick={handlePromoClick}
            target={"_blank"}
          >
            {item.ctaLabel}
          </PillButton>
        </Box>
      </div>
    </>
  );
};

export default Promotion;
export { promoToTrackingObject } from "./tracking";

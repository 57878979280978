import clsx from "clsx";
import { Fragment, memo, useMemo, useState } from "react";
import { connectRefinementList } from "react-instantsearch-dom";

import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@material-ui/core";

import { trackRefineFilter } from "../../../tracking";
import ShowMoreButton from "./ShowMoreButton";
import { useStyles } from "./styles";

// import { useSelector } from "react-redux";

// import { AccordionPanelContext } from "src/components/Accordion/Panel";

const RefinementList = ({
  attribute,
  items,
  refine,
  showMore = false,
  limit,
  showMoreLimit,
  // attribute,
  descriptions = {},
  // showItemsWithNoResults = false,
  noOptionsAvailableText = "No options available.",
}) => {
  const classes = useStyles();

  const [showingMore, setShowingMore] = useState(false);
  // const shadowItems = useSelector(
  //   (state) => state?.search?.items?.[attribute] ?? []
  // );
  // const allItems = useSelector(
  //   (state) => state?.search?.initialItems?.[attribute] ?? []
  // );

  // const { hideSelf, showSelf } = useContext(AccordionPanelContext);
  // useEffect(() => {
  //   if (items.length > 0) showSelf();
  //   else hideSelf();
  // }, [items.length, showSelf, hideSelf]);
  //const itemsToShow = showItemsWithNoResults ? allItems : items;
  const itemsToShow = items;

  const showMoreEnabled = useMemo(
    () => showMore && itemsToShow.length > limit + 1,
    [showMore, itemsToShow, limit]
  );

  const visibleItems = useMemo(
    () =>
      showMoreEnabled
        ? itemsToShow.slice(0, showingMore ? showMoreLimit : limit)
        : itemsToShow,
    [itemsToShow, showMoreEnabled, showMoreLimit, showingMore, limit]
  );

  return visibleItems.length === 0 ? (
    <Typography color="textSecondary">{noOptionsAvailableText}</Typography>
  ) : (
    <>
      <FormGroup>
        {visibleItems.map((item) => {
          const actualItem = items.find(
            (actualItem) => actualItem.label === item.label
          );
          // const facetCount =
          //   actualItem && actualItem.count
          //     ? shadowItems.find(
          //         (shadowItem) => shadowItem.label === item.label
          //       )?.count ?? 0
          //     : 0;

          const facetCount = actualItem.count;

          const isRefined = actualItem ? actualItem.isRefined : false;
          return (
            <Fragment key={item.label}>
              <FormControlLabel
                disabled={!isRefined && facetCount === 0}
                control={
                  <Checkbox
                    checked={isRefined}
                    onChange={() => {
                      if (actualItem) {
                        refine(actualItem.value);
                        trackRefineFilter(attribute, actualItem.value);
                      }
                    }}
                    name={item.label}
                    color="primary"
                  />
                }
                label={
                  <Box>
                    <Typography
                      noWrap
                      className={facetCount === 0 ? classes.labelDisabled : ""}
                      component="span"
                    >
                      {item.label}
                    </Typography>
                    <Typography
                      color={facetCount === 0 ? "textSecondary" : "primary"}
                      variant="caption"
                      component="span"
                      className={clsx(
                        classes.labelCount,
                        facetCount === 0 ? classes.labelDisabled : ""
                      )}
                    >
                      ({facetCount})
                    </Typography>
                  </Box>
                }
              />

              {descriptions && descriptions[item.label] && (
                <Typography variant="caption" className={classes.description}>
                  {descriptions[item.label]}
                </Typography>
              )}
            </Fragment>
          );
        })}
      </FormGroup>
      {showMoreEnabled && (
        <ShowMoreButton
          showingMore={showingMore}
          onClick={() => setShowingMore(!showingMore)}
        />
      )}
    </>
  );
};

const CustomRefinementList = connectRefinementList(memo(RefinementList));

export default CustomRefinementList;

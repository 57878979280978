import { memo } from "react";

import { useTabsData } from "./hooks";

import { DisplayOnScreenSize } from "src/components/shared";
import TabsDesktop from "./TabsDesktop";
import TabsMobile from "./TabsMobile";

const Tabs = ({
  childAttributes,
  collections,
  childPages,
  collectionPages,
}) => {
  const tabsData = useTabsData(
    childAttributes,
    collections,
    childPages,
    collectionPages
  );

  if (!tabsData.length) return null;

  return (
    <>
      <DisplayOnScreenSize hideDown="xs">
        <TabsDesktop tabsData={tabsData} />
      </DisplayOnScreenSize>

      <DisplayOnScreenSize hideUp="sm">
        <TabsMobile tabsData={tabsData} />
      </DisplayOnScreenSize>
    </>
  );
};

export default memo(Tabs);

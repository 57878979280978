import fecha from "fecha";
import UserPrice from "src/components/UserPrice";
import {
  AccommodationCategoriesRefinementList,
  DatePicker,
  RangeSlider,
  RangeSliderValueLabel,
  RefinementList,
} from "src/components/explore/Search/components/Filters";
import MinMaxDropdowns from "src/components/explore/Search/components/Filters/MinMaxDropdowns";
import {
  epochToDate,
  epochToUTCISODateString,
  isoDateStringToUTCSeconds,
} from "src/utils/date";
import pluralize from "src/utils/pluralize";

import { renderNodeRange, renderStringRange } from "./helpers";
import attributeContent from "./search-attribute-content.json";

export const findSearchConfigSection = (attributeName) => {
  return searchConfig.sections.find((section) =>
    section.filters.some((filter) => filter.attribute === attributeName)
  );
};

const dateValueToUrlParam = (x) =>
  x !== undefined ? epochToUTCISODateString(x) : "*";

const dateUrlParamToValue = (x) => {
  return isoDateStringToUTCSeconds(x);
};

const durationTransformCurrentRefinement = ({ min, max }) =>
  renderStringRange(min, max, " Night");

const dateTransformCurrentRefinement = (currentRefinement, min, max) => {
  const dateFormat = "DD/MM/YYYY";

  return renderStringRange(
    currentRefinement.min !== undefined && currentRefinement.min > min
      ? fecha.format(epochToDate(currentRefinement.min), dateFormat)
      : null,
    currentRefinement.max !== undefined && currentRefinement.max < max
      ? fecha.format(epochToDate(currentRefinement.max), dateFormat)
      : null
  );
};

const durationValueLabelFormat = (value) => (
  <RangeSliderValueLabel>
    {value}&nbsp;night
    {pluralize(value)}
  </RangeSliderValueLabel>
);

const priceRangeTransformCurrentRefinement = ({ min, max }) =>
  renderNodeRange(
    min !== undefined ? (
      <UserPrice value={min} currencyCode="GBP" round />
    ) : null,
    max !== undefined ? (
      <UserPrice value={max} currencyCode="GBP" round />
    ) : null
  );

const priceRangeValueLabelFormat = (value) => (
  <RangeSliderValueLabel>
    <UserPrice value={value} currencyCode="GBP" round />
  </RangeSliderValueLabel>
);

export const searchConfig = {
  sections: [
    {
      title: "Activity",
      icon: "Leap",
      filters: [
        {
          attribute: "activity",
          type: "menu",
          Component: RefinementList,
          ComponentProps: {
            showItemsWithNoResults: true,
            showMore: true,
            limit: 5,
            showMoreLimit: 200,
          },
        },
      ],
    },
    {
      title: "Continent",
      icon: "Earth",
      filters: [
        {
          attribute: "continent",
          type: "menu",
          Component: RefinementList,
          ComponentProps: {
            showItemsWithNoResults: true,
            showMore: true,
            limit: 5,
            showMoreLimit: 200,
          },
        },
      ],
    },
    {
      title: "Country",
      icon: "Map",
      filters: [
        {
          attribute: "country",
          type: "refinementList",
          Component: RefinementList,
          ComponentProps: {
            noOptionsAvailableText:
              "No countries match your selected criteria, please try modifying your filter selections.",
            showItemsWithNoResults: false,
            showMore: true,
            limit: 5,
            showMoreLimit: 200,
          },
        },
      ],
    },
    {
      title: "Collection",
      icon: "Rocket",
      filters: [
        {
          attribute: "collection",
          type: "refinementList",
          Component: RefinementList,
        },
      ],
    },
    {
      title: "Accommodation",
      icon: "WildCamping",
      filters: [
        {
          attribute: "accommodationCategory",
          type: "refinementList",
          Component: AccommodationCategoriesRefinementList,
          ComponentProps: {
            showItemsWithNoResults: true,
            showMore: true,
            limit: 5,
            showMoreLimit: 200,
          },
        },
      ],
    },
    {
      title: "Dates",
      icon: "Calendar",
      filters: [
        {
          attribute: "startDate",
          type: "range",
          Component: DatePicker,
          ComponentProps: {
            openEnded: false,
            label: "When would you like to go?",
          },
          valueToURLParam: dateValueToUrlParam,
          urlParamToValue: dateUrlParamToValue,
          transformCurrentRefinement: dateTransformCurrentRefinement,
        },
      ],
    },
    {
      title: "Duration",
      icon: "Sleep",
      filters: [
        {
          attribute: "duration",
          type: "range",
          Component: MinMaxDropdowns,
          transformCurrentRefinement: durationTransformCurrentRefinement,
          ComponentProps: {
            valueLabelFormat: durationValueLabelFormat,
            minLabel: "Min Nights",
            maxLabel: "Max Nights",
            label: "How many nights away?",
          },
        },
      ],
    },
    {
      title: "Price range",
      icon: "Money",
      filters: [
        {
          attribute: "priceGBP",
          type: "range",
          Component: RangeSlider,
          transformCurrentRefinement: priceRangeTransformCurrentRefinement,
          ComponentProps: {
            step: 50,
            valueLabelFormat: priceRangeValueLabelFormat,
          },
        },
      ],
    },
    // Removing Group Size until such time as we can get people to understand
    // what it means
    // (it means: Show me products that have room for a group between x and y in size)
    // {
    //   title: "Group size",
    //   icon: "PersonWave",
    //   filters: [
    //     {
    //       attribute: "groupSize",
    //       type: "range",
    //       Component: RangeSlider,
    //       transformCurrentRefinement: ({ min, max }) => {
    //         if (min === max) {
    //           return `${max} ${pluralize(max, "person", "people")}`;
    //         }
    //         return renderStringRange(
    //           min !== undefined ? min : null,
    //           max !== undefined
    //             ? `${max} ${pluralize(max, "person", "people")}`
    //             : null
    //         );
    //       },
    //       ComponentProps: {
    //         valueLabelFormat: (value) => (
    //           <RangeSliderValueLabel>{value}</RangeSliderValueLabel>
    //         ),
    //       },
    //     },
    //   ],
    // },
    {
      title: "Difficulty",
      icon: "PersonRun",
      filters: [
        {
          attribute: "difficulty",
          attributeContent: attributeContent["difficulty"],
          type: "refinementList",
          Component: RefinementList,
          attributeContent: attributeContent["difficulty"],
          ComponentProps: {
            showItemsWithNoResults: true,
            showMore: false,
            limit: 5,
            showMoreLimit: 200,
          },
        },
      ],
    },
    {
      title: "Promotions",
      icon: "Offer",
      filters: [
        {
          attribute: "promotion",
          type: "refinementList",
          Component: RefinementList,
          ComponentProps: {
            showItemsWithNoResults: true,
            showMore: false,
            limit: 5,
            showMoreLimit: 200,
          },
        },
      ],
    },
    // {
    //   title: "Travel Restrictions",
    //   icon: "Warning",
    //   filters: [
    //     {
    //       attribute: "noQuarantineReq",
    //       type: "menu",
    //       Component: NoQuarantineMenu,
    //       transformCurrentRefinement: transformNoQuarantineReqLabel,
    //       transformLabel: transformNoQuarantineReqLabel,
    //       attributeContent: attributeContent["noQuarantineReq"],
    //       ComponentProps: {},
    //     },
    //   ],
    // },
  ],
};

import clsx from "clsx";

import { Box, createStyles, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    verticalGutter: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    noBackground: {
      background: "none",
    },
    white: {
      backgroundColor: "white",
    },
    light: {
      backgroundColor: grey[100],
    },
    gradient: {
      background: `linear-gradient(0deg, ${theme.palette.grey[200]} 0%, ${theme.palette.grey[100]} 100%)`,
      background: `linear-gradient(0deg, ${theme.palette.grey[200]} 0%, ${theme.palette.grey[50]} 100%)`,
    },
    invertedGradient: {
      background: `linear-gradient(180deg, ${theme.palette.grey[200]} 0%, ${theme.palette.grey[100]} 100%)`,
      background: `linear-gradient(180deg, ${theme.palette.grey[200]} 0%, ${theme.palette.grey[50]} 100%)`,
    },
  })
);

const Row = ({
  variant = "gradient",
  disableVerticalGutter = false,
  className,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(
        classes[variant],
        {
          [classes.verticalGutter]: !disableVerticalGutter,
        },
        className
      )}
      {...props}
    />
  );
};

export default Row;

import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  sectionActiveFiltersRoot: {
    textAlign: "left",

    "&:not(:empty)": {
      // only add the top padding if we actually have some filters
      // otherwise it's best to leave it as an empty div with no size
      paddingTop: theme.spacing(2),
    },
  },
}));

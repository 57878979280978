import React from "react";
import { connectMenu } from "react-instantsearch-dom";

import { TextField, Typography } from "@material-ui/core";

function groupBy(xs, f) {
  return xs.reduce(
    (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r),
    {}
  );
}

const MenuSelect = ({
  items,
  currentRefinement,
  refine,
  transformLabel = (v) => v,
}) => {
  const groups = groupBy(items, (item) => item.label.split("_")[0]);

  return (
    <>
      <Typography variant="caption">
        Please select your vaccination status and where you will be travelling
        from to view trips that you can currently travel to without having to
        quarantine on arrival.
      </Typography>
      <TextField
        select
        margin="normal"
        SelectProps={{
          native: true,
        }}
        InputLabelProps={{ shrink: true }}
        fullWidth
        variant="outlined"
        label="Traveling from / Vaccination status"
        onChange={(e) => refine(e.target.value)}
        value={currentRefinement || ""}
      >
        <option value={[]}>Please choose...</option>
        {Object.keys(groups).map((group) => (
          <optgroup label={`Travelling from ${group}`} key={group}>
            {groups[group].map((item) => (
              <option
                key={item.value}
                value={item.isRefined ? currentRefinement : item.value}
              >
                {transformLabel(item.label)}
              </option>
            ))}
          </optgroup>
        ))}
      </TextField>
    </>
  );
};

export default connectMenu(MenuSelect);

import clsx from "clsx";
import { memo } from "react";
import ReactMarkdown from "react-markdown";
import Breadcrumbs from "src/components/Breadcrumbs";
import { Photograph } from "src/components/Image";

import { Box, Grid, Typography, useTheme } from "@material-ui/core";

import { useBreadcrumbPaths } from "./hooks";
import { makeImageSizes, useStyles } from "./styles";

const Hero = ({
  imageSrc,
  title,
  subtitle,
  intro,
  showBreadcrumbs,
  currentPath,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const breadcrumbPaths = useBreadcrumbPaths(currentPath);

  const hasBackgroundImage = !!imageSrc;

  return (
    <Box
      className={clsx(classes.root, {
        [classes.noBgImage]: !hasBackgroundImage,
      })}
    >
      {hasBackgroundImage && (
        <Box className={classes.bgImage}>
          <Photograph
            priority={true}
            alt=""
            src={imageSrc}
            sizes={makeImageSizes(theme)}
          />
        </Box>
      )}

      <Box paddingX={[2, 2, 10]}>
        <Grid container className={classes.container}>
          <Grid item xl={1} />
          <Grid item xs={12} sm={10} xl={9}>
            {showBreadcrumbs && <Breadcrumbs paths={breadcrumbPaths} />}

            <Typography variant="h1" className={classes.title}>
              {title}
            </Typography>

            {subtitle && (
              <Typography
                variant="subtitle1"
                component="div"
                className={classes.subtitle}
              >
                <div className={classes.content}>
                  <ReactMarkdown>{subtitle}</ReactMarkdown>
                </div>
              </Typography>
            )}

            {intro && (
              <Typography
                variant="body1"
                component="div"
                className={classes.intro}
              >
                <div className={classes.content}>
                  <ReactMarkdown>{intro}</ReactMarkdown>
                </div>
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default memo(Hero);

import {
  EVENT_NAME_CLICK_GRID_ITEM,
  trackAnalyticsEvent,
} from "src/utils/track";

import { promoToTrackingObject } from "../Promotion";

export const trackLinkItemClick = (page) => {
  trackAnalyticsEvent(EVENT_NAME_CLICK_GRID_ITEM, {
    type: page.attribute,
    linkItem: page,
  });
};

export const trackPromoClick = (promo) => {
  trackAnalyticsEvent(EVENT_NAME_CLICK_GRID_ITEM, {
    type: "promotion",
    ...promoToTrackingObject(promo),
  });
};

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import clsx from "clsx";
import { useEffect, useRef, useState, memo } from "react";
import useSwiperRef from "src/hooks/useSwiperRef";
import { A11y, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { Box, ButtonBase } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ChevronLeft,
  ChevronRight,
} from "@muchbetteradventures/components/dist/Icons";
import useResize from "src/hooks/useResize";

import { useStyles, navButtonWidthDesktop, spacing } from "./styles";

const DesktopSwiper = ({ slides, slideClassName, onSlideChange }) => {
  const classes = useStyles();
  const theme = useTheme();

  // Swiper Navigation Button Refs
  const [swiper, setSwiper] = useState(null);
  const [prevEl, prevRef] = useSwiperRef();
  const [nextEl, nextRef] = useSwiperRef();

  useEffect(() => {
    if (swiper) {
      swiper.update();
      if (swiper.navigation) swiper.navigation.update();
      if (swiper.pagination) swiper.pagination.update();
    }
  }, [slides, swiper]);

  // Swiper Navigation Group Sizes
  // -------
  //
  // Slide widths are determined by css for SSR reasons,
  // so we don't have those width values available on the server,
  // and so we can't determine the number of slides on the screen.
  //
  // Instead, 'lazy load' the navigation after mounting by calculating the group sizes based on the rendered slide widths
  const [slidesPerGroup, setSlidesPerGroup] = useState(3);
  const [hideNavigation, setHideNavigation] = useState(false);

  const containerRef = useRef(null);
  const slideRef = useRef(null);
  const { width: containerWidth } = useResize(containerRef);
  const { width: slideWidth } = useResize(slideRef);

  useEffect(() => {
    if (containerWidth > 0 && slideWidth > 0) {
      const totalSlideWidth = slideWidth + theme.spacing(spacing);
      const totalContainerWidth =
        containerWidth - theme.spacing(navButtonWidthDesktop) * 2;

      const maxSlidesInContainer = Math.floor(
        totalContainerWidth / totalSlideWidth
      );

      const slidesOverflowContainer = slides.length > maxSlidesInContainer;

      setSlidesPerGroup(maxSlidesInContainer);
      setHideNavigation(!slidesOverflowContainer);
    }
  }, [theme, slides.length, containerWidth, slideWidth]);

  // SSR Spacing
  // ------
  //
  // Ideally for SSR, we'd use 'margin-right' to determine the spacing between elements
  // But swiper needs to be given the 'spaceBetween' explicitly to properly calculate nav.
  //
  // As a bit of a hack, we initially load in using margin-right,
  // then on mount we remove the margin and let swiper take over
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <Box position="relative" ref={containerRef}>
      <Swiper
        onSwiper={setSwiper}
        modules={[Pagination, Navigation, A11y]}
        navigation={{ prevEl, nextEl }}
        allowTouchMove={true}
        slidesPerView="auto"
        slidesPerGroup={slidesPerGroup}
        onSlideChange={onSlideChange}
        spaceBetween={theme.spacing(spacing)}
        pagination={{
          clickable: true,
          bulletActiveClass: classes.navigationActiveBullet,
        }}
        className={classes.swiper}
      >
        {slides.map((slide, idx) => (
          <SwiperSlide
            key={idx}
            className={clsx(
              slideClassName,
              { [classes.slideMounted]: mounted },
              classes.slideBase
            )} /* ordered so overridding classname gets priority */
            ref={idx === 0 ? slideRef : null}
          >
            {() => slide}
          </SwiperSlide>
        ))}
      </Swiper>
      <Box display={hideNavigation ? "none" : "inherit"}>
        <Box left={0} className={classes.navigationButtonContainerDesktop}>
          <ButtonBase
            ref={prevRef}
            disableRipple={true}
            className={clsx(
              classes.navigationButtonDesktop,
              classes.navigationPrevButtonDesktop
            )}
          >
            <ChevronLeft />
          </ButtonBase>
        </Box>
        <Box right={0} className={classes.navigationButtonContainerDesktop}>
          <ButtonBase
            ref={nextRef}
            disableRipple={true}
            className={clsx(
              classes.navigationButtonDesktop,
              classes.navigationNextButtonDesktop
            )}
          >
            <ChevronRight />
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(DesktopSwiper);

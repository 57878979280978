import { useEffect, useRef } from "react";
import { useIntersection } from "src/hooks/useIntersection";

import {
  EVENT_NAME_PROMOTION_VIEWED,
  EVENT_NAME_PROMOTION_CLICKED,
  trackAnalyticsEvent,
} from "src/utils/track";

export const useTrackView = (item) => {
  const ref = useRef();
  const inViewport = useIntersection(ref, "-200px");

  useEffect(() => {
    if (inViewport) {
      trackAnalyticsEvent(
        EVENT_NAME_PROMOTION_VIEWED,
        promoToTrackingObject(item)
      );
    }
  }, [inViewport, item]);

  return ref;
};

export const trackClick = (item) => {
  trackAnalyticsEvent(
    EVENT_NAME_PROMOTION_CLICKED,
    promoToTrackingObject(item)
  );
};

export const promoToTrackingObject = ({
  title,
  backgroundColor,
  ctaLabel,
  ctaUrl,
  image,
  imageStyle,
  ...restPromo
}) => ({
  // standard ecommerce properties
  name: title,
  promotion_id: ctaLabel.replace(" ", "-").toLowerCase(),
  creative: image?.url,
  position: document.location.pathname,

  // change camel to snake case for remainders
  background_color: backgroundColor,
  cta_label: ctaLabel,
  cta_url: ctaUrl,
  image_style: imageStyle,
  ...restPromo,
});

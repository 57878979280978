import React from "react";

import { Button } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { createStyles, makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    textButton: {
      color: grey[600],
      paddingLeft: 0,
      paddingRight: 0,
      fontSize: 15,

      "&:hover": {
        color: "black",
        background: "none",
      },
    },
  })
);

const TextButton = ({ children, ...rest }) => {
  const classes = useStyles();

  return (
    <div>
      <Button
        className={classes.textButton}
        size="small"
        variant="text"
        disableRipple
        {...rest}
      >
        {children}
      </Button>
    </div>
  );
};

export default TextButton;

import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
  createStyles({
    imageOverlay: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      padding: `${theme.spacing(4)}px 10%`,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
      textAlign: "center",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        padding: `${theme.spacing(3)}px 10%`,
      },
    },
    textLight: {
      color: "white",
    },
    textDark: {
      color: "black",
    },
    imageOverlayLight: {
      background:
        "linear-gradient(0deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.3) 100%)",
    },
    imageOverlayDark: {
      background:
        "linear-gradient(0deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.5) 100%)",
    },
    imageContainer: {
      position: "relative",
      height: 120,
      width: "80%",
    },
    title: {
      fontWeight: 900,
      lineHeight: 1,
      fontSize: 40,
      [theme.breakpoints.down("sm")]: {
        fontSize: 30,
      },
    },
    overline: {
      display: "block",
    },
    subtitle: {
      fontWeight: 700,
      lineHeight: 1.3,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
  })
);

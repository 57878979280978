import { useContext, useEffect, useState } from "react";
import { connectRange } from "react-instantsearch-dom";
import { AccordionPanelContext } from "src/components/Accordion/Panel";

import { Box, Slider, createStyles, makeStyles } from "@material-ui/core";

import { trackRefineFilter } from "../../../tracking";

const useStyles = makeStyles(() =>
  createStyles({
    valueLabel: {
      top: -22,
      "& *": {
        background: "transparent",
        color: "#000",
      },
    },
  })
);

const RangeSlider = ({
  attribute,
  min,
  max,
  currentRefinement,
  refine,
  step,
  valueLabelFormat,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState([
    currentRefinement.min || min,
    currentRefinement.max || max,
  ]);

  useEffect(() => {
    setValue([currentRefinement.min || min, currentRefinement.max || max]);
  }, [currentRefinement, min, max]);

  const handleChangeCommitted = () => {
    const range = { min: value[0] || min, max: value[1] || max };
    refine(range);
    trackRefineFilter(attribute, range);
  };

  const { hideSelf, showSelf } = useContext(AccordionPanelContext);
  useEffect(() => {
    if (min < max) showSelf();
    else hideSelf();
  }, [min, max, showSelf, hideSelf]);

  return (
    <Box px={3} paddingTop={4}>
      <Slider
        classes={classes}
        min={min}
        max={max}
        value={value}
        step={step}
        onChange={(_, value) => {
          setValue(value);
        }}
        onChangeCommitted={handleChangeCommitted}
        valueLabelDisplay="on"
        aria-labelledby="range-slider"
        valueLabelFormat={valueLabelFormat}
      />
    </Box>
  );
};

export default connectRange(RangeSlider);

import { memo } from "react";

import AttributeCarousel from "../AttributeCarousel";

const CollectionsCarousel = ({ collectionPages, ...rest }) => (
  <AttributeCarousel
    currentPath={[]}
    minItems={1}
    pages={collectionPages}
    {...rest}
  />
);

export default memo(CollectionsCarousel);

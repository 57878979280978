import { makeStyles } from "@material-ui/core";

export const gutterMobile = 2;
export const gutterDesktop = 10;
export const spacing = 2;
export const navButtonWidthDesktop = gutterDesktop - spacing;

export const makeSlideWidthHeight = (
  theme,
  numSlides,
  { aspectRatio, absoluteHeight }
) => {
  const totalSpacing = `${theme.spacing(spacing) * (numSlides - 1)}px`;
  const widthFormula = `((100% - ${totalSpacing}) / ${numSlides})`;
  const heightFormula = `${widthFormula} * ${aspectRatio}`;

  const width = `calc(${widthFormula})`;
  const height = `calc(${heightFormula})`;

  const resp = { paddingTop: 0 };
  resp.width = width;
  if (typeof absoluteHeight !== "undefined") {
    resp.height = absoluteHeight;
  } else {
    resp.paddingTop = height;
  }

  return resp;
};

export const makeDefaultSlideStyles = (theme) => ({
  marginRight: theme.spacing(spacing),

  ...makeSlideWidthHeight(theme, 1.5, { aspectRatio: 1 }),

  [theme.breakpoints.up(350)]: {
    ...makeSlideWidthHeight(theme, 2.2, { aspectRatio: 1 }),
  },
  [theme.breakpoints.up("sm")]: {
    ...makeSlideWidthHeight(theme, 3.25, { aspectRatio: 1 }),
  },
  [theme.breakpoints.up("md")]: {
    ...makeSlideWidthHeight(theme, 4, { aspectRatio: 1 }),
    marginBottom: theme.spacing(5),
  },
  [theme.breakpoints.up("lg")]: {
    ...makeSlideWidthHeight(theme, 5, { aspectRatio: 1 }),
    marginBottom: theme.spacing(5),
  },
  [theme.breakpoints.up("xl")]: {
    ...makeSlideWidthHeight(theme, 8, { aspectRatio: 1 }),
    marginBottom: theme.spacing(5),
  },
});

export const makeDefaultImageSizes = (theme) => `
  (max-width: 350px) 70vw,
  (max-width: ${theme.breakpoints.values["sm"]}px) 45vw,
  (max-width: ${theme.breakpoints.values["md"]}px) 30vw,
  (max-width: ${theme.breakpoints.values["lg"]}px) 25vw,
  (max-width: ${theme.breakpoints.values["xl"]}px) 20vw,
  15vw
`;

export const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(gutterMobile),

    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(gutterDesktop),
    },
  },
  swiper: {
    // Swiper handles the gutter via js which isn't SSR friendly when we want it to be different on different devices.
    // We essentially need to create our own gutter using padding and an overflow instead
    paddingLeft: theme.spacing(gutterMobile),
    paddingRight: theme.spacing(gutterMobile),

    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(gutterDesktop),
      paddingRight: theme.spacing(gutterDesktop),
    },
  },
  slideBase: {
    ...makeDefaultSlideStyles(theme),
  },
  slideMounted: {
    marginRight: "0px",
  },
  navigationButtonContainerDesktop: {
    position: "absolute",
    top: 0,
    bottom: theme.spacing(5),
    zIndex: 2,

    width: theme.spacing(navButtonWidthDesktop),
  },
  navigationButtonDesktop: {
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    width: "100%",
    height: "100%",
  },
  navigationPrevButtonDesktop: {
    borderRadius: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px 0`,
  },
  navigationNextButtonDesktop: {
    borderRadius: `${theme.spacing(2)}px 0 0 ${theme.spacing(2)}px`,
  },
  navigationActiveBullet: {
    background: "#E76135",
    opacity: 1,
  },
  "@global": {
    ".swiper-button-disabled": {
      display: "none",
    },
  },
}));

import { memo } from "react";
import pluralize from "src/utils/pluralize";

import {
  Box,
  Card,
  CardActionArea,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Photograph } from "src/components/Image";

import { makeImageSizes, useStyles } from "./styles";

const MagazineArticle = ({ magazineArticle, onClick }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Card className={classes.cardRoot} variant="outlined">
      <CardActionArea
        className={classes.cardActionArea}
        href={magazineArticle.url}
        title={magazineArticle.title}
        onClick={onClick}
        target="_blank"
      >
        <Box className={classes.imageContainer}>
          <Photograph
            className={classes.image}
            src={magazineArticle.image.url}
            alt={magazineArticle.title}
            sizes={makeImageSizes(theme)}
          />
        </Box>
        <Box p={2}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6" gutterBottom>
              {magazineArticle.category}
            </Typography>
            <Typography variant="h6">
              {`${magazineArticle.minutes} min${pluralize(
                magazineArticle.minutes
              )}`}
            </Typography>
          </Box>
          <Typography variant="h4">{magazineArticle.title}</Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default memo(MagazineArticle);

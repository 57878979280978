import React from "react";

import { Help } from "@muchbetteradventures/components/dist/Icons";

import InformationDialog from "./InformationDialog";
import TextButton from "../shared/TextButton";

const AttributeContent = ({ attributeContent }) => {
  const [showInformation, setShowInformation] = React.useState(false);

  return (
    <>
      <TextButton
        onClick={() => setShowInformation(!showInformation)}
        startIcon={<Help />}
      >
        {attributeContent.buttonLabel}
      </TextButton>
      <InformationDialog
        title={attributeContent.title}
        content={attributeContent.content}
        handleClose={() => {
          setShowInformation(false);
        }}
        open={showInformation}
      />
    </>
  );
};
export default AttributeContent;

import { addTrailingSlash } from "../../utils/addTrailingSlash";
import { removeTrailingIndex } from "../../utils/removeTrailingIndex";

const PUBLIC_SITE_URL = process.env.NEXT_PUBLIC_SITE_URL;

const explorePageIndexingStrategy = (
  asPath,
  canonicalBaseUrl = PUBLIC_SITE_URL
) => {
  const [, querystring] = asPath.split("?");
  const searchParams = new URLSearchParams(querystring);

  const selfReferencingCanonicalPath = addTrailingSlash(
    removeTrailingIndex(asPath)
  );
  const selfReferencingCanonical = `${canonicalBaseUrl}${selfReferencingCanonicalPath}`;

  // If there's no querystring present, then index the page and add it to the sitemap.
  //
  // We want every primary explore page to easily indexable and highly ranked.
  //
  if (!querystring) {
    return {
      robots: "all",
      sitemap: true,
      canonical: selfReferencingCanonical,
    };
  }

  // If there's a querystring, but only containing pagination parameters
  // then we want search engines to recognise this as a page in its own right
  // but not have it added to the sitemap.
  //
  if (searchParamsOnlyContainPaginationKeys(searchParams)) {
    return {
      robots: "all",
      sitemap: false,
      canonical: selfReferencingCanonical,
    };
  }

  // Otherwise this page has additional query parameters
  // so we want to hide it to focus the search engine on the primary pages
  //
  return {
    robots: "noindex",
    sitemap: false,
    canonical: null,
  };
};

const searchParamsOnlyContainPaginationKeys = (
  searchParams,
  paginationKeys = ["page"]
) => {
  for (const key of searchParams.keys()) {
    if (!paginationKeys.includes(key)) {
      return false;
    }
  }

  return true;
};

export default explorePageIndexingStrategy;

import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import * as Icons from "@muchbetteradventures/components/dist/Icons";

import { kebabToCamel } from "../../utils/camel-kebab";

const styles = (theme) => ({
  icon: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    fontSize: 13,
  },
  container: {
    minHeight: 24,
  },
  value: {
    lineHeight: "24px",
  },
});

const ProductAttribute = ({
  classes,
  icon,
  iconColor = null,
  value,
  alignment = "left",
}) => {
  const Icon = icon ? Icons[icon] || Icons[kebabToCamel(`-${icon}`)] : null;

  return (
    <Box
      display="flex"
      className={classes.container}
      justifyContent={alignment === "left" ? "flex-start" : "flex-end"}
    >
      {Icon && (
        <Icon className={classes.icon} htmlColor={iconColor || "inherit"} />
      )}
      <Typography
        className={classes.value}
        color="inherit"
        variant="overline"
        component="p"
      >
        {value}
      </Typography>
    </Box>
  );
};

export default withStyles(styles)(ProductAttribute);

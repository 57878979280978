import React, { useCallback } from "react";
import { connectMenu } from "react-instantsearch-dom";

import { Select } from "@muchbetteradventures/styleguide";

import { trackRefineFilter } from "../../tracking";

const MenuSelect = connectMenu(
  ({
    allItemsOptionLabel,
    attribute,
    items,
    currentRefinement,
    refine,
    icon,
    placeholder,
    colorVariant,
  }) => {
    const ALL_OPTIONS_SELECT_OPTION_VALUE = "0";

    const selectOptions = items.reduce((acc, item) => {
      // Algolia changes the value of the selected item to ""
      // so if we use the algolia item values for our select option values,
      // then currentRefinement (eg "Africa")
      // won't match up with the currently selected option's value ("")
      // and our select will still show the placeholder.
      //
      // Using label instead allows us to circumvent that
      // But we lose the ability to reset the select by clicking the currently selected value again
      // which is not desired behaviour for us anyway
      const value = item.label;
      acc[value] = {
        name: item.label,
        subtext: ` [${item.count}]`,
      };
      return acc;
    }, {});

    selectOptions[ALL_OPTIONS_SELECT_OPTION_VALUE] = {
      name: allItemsOptionLabel ?? "Reset",
    };

    const handleChange = useCallback(
      (selectValue) => {
        let refineValue = "";

        if (selectValue === ALL_OPTIONS_SELECT_OPTION_VALUE) {
          refineValue = "";
        } else {
          const item = items.find((item) => item.label === selectValue);
          refineValue = item.value;
        }

        refine(refineValue);
        trackRefineFilter(attribute, refineValue);
      },
      [attribute, items, refine]
    );

    return (
      <Select
        colorVariant={colorVariant}
        icon={icon}
        onChange={handleChange}
        options={selectOptions}
        placeholder={placeholder}
        value={currentRefinement}
      />
    );
  }
);

export default MenuSelect;

import { memo, useCallback } from "react";

import { TabIcon, TabLabel } from "./components";
import {
  StyledMuiTabs,
  StyledMuiTab,
  StyledMuiTabsSingleTab,
  StyledMuiTabSingleTab,
  useStyles,
} from "./styles";

import { trackTabChange } from "./tracking";

const TabNavigation = ({ currentTabIndex, onChange, tabsData }) => {
  const classes = useStyles();

  const makeHandleClick = useCallback((tab) => () => trackTabChange(tab), []);

  if (tabsData.length === 1) {
    const tab = tabsData[0];

    return (
      <div className={classes.root}>
        <StyledMuiTabsSingleTab value={currentTabIndex} onChange={onChange}>
          <StyledMuiTabSingleTab
            key={tab.name}
            label={<TabLabel text={tab.displayName} />}
            icon={<TabIcon iconName={tab.iconName} />}
          />
        </StyledMuiTabsSingleTab>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <StyledMuiTabs
        value={currentTabIndex}
        onChange={onChange}
        aria-labelledby="explore page related attributes tabs"
      >
        {tabsData.map((tab, index) => (
          <StyledMuiTab
            id={`a11y-tab-${index}`}
            aria-controls={`a11y-tabpanel-${index}`}
            key={tab.name}
            label={<TabLabel text={tab.displayName} />}
            icon={<TabIcon iconName={tab.iconName} />}
            onClick={makeHandleClick(tab)}
          />
        ))}
      </StyledMuiTabs>
    </div>
  );
};

export default memo(TabNavigation);

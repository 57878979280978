import { memo } from "react";
import { connectRange, connectRefinementList } from "react-instantsearch-dom";

import { Box } from "@material-ui/core";

import { ActiveFilterChip, ClearAllChip } from "./components";
import { useStyles } from "./styles";

const ActiveRefinementList = ({ items, refine, currentRefinement }) => {
  return (
    <>
      {items.map(
        (item) =>
          item.isRefined && (
            <ActiveFilterChip
              key={item.label}
              value={item.value}
              label={item.label}
              refine={refine}
            />
          )
      )}

      {!!currentRefinement.length && currentRefinement.length > 1 && (
        <ClearAllChip refine={refine} />
      )}
    </>
  );
};

export const ConnectedRefinementList = connectRefinementList(
  memo(ActiveRefinementList)
);

const ActiveRange = ({
  refine,
  currentRefinement,
  min,
  max,
  transformCurrentRefinement = (v) => `${v.min} to ${v.max}`,
}) => {
  const shouldRender =
    (!!currentRefinement.min && currentRefinement.min !== min) ||
    (!!currentRefinement.max && currentRefinement.max !== max);

  return (
    shouldRender && (
      <ActiveFilterChip
        label={transformCurrentRefinement(currentRefinement, min, max)}
        value={{ min, max }}
        refine={refine}
      />
    )
  );
};

export const ConnectedRange = connectRange(memo(ActiveRange));

const SectionActiveFilters = ({ section }) => {
  const classes = useStyles();

  return (
    <Box className={classes.sectionActiveFiltersRoot}>
      {section.filters.map(
        ({ attribute, type, transformCurrentRefinement }) => {
          if (type === "refinementList")
            return (
              <ConnectedRefinementList key={attribute} attribute={attribute} />
            );
          if (type === "range")
            return (
              <ConnectedRange
                key={attribute}
                attribute={attribute}
                transformCurrentRefinement={transformCurrentRefinement}
              />
            );
          return null;
        }
      )}
    </Box>
  );
};

export default memo(SectionActiveFilters);

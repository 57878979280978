export const NoSearchResultsError = new Error("No Results");

export const getSearchResults = async (searchResultsProvider) => {
  const resultsRaw = await searchResultsProvider();
  const results = JSON.parse(JSON.stringify(resultsRaw));

  const numHits = results.rawResults[0] && results.rawResults[0].nbHits;
  if (!numHits) throw NoSearchResultsError;

  return results;
};

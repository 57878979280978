import { memo } from "react";

import {
  AttributeCarousel,
  CollectionsCarousel,
} from "src/components/explore/carousels";

const TabsMobile = ({ tabsData }) => {
  const getTabRow = (tab) => {
    const carouselProps = {
      key: tab.name,
      title: tab.displayName,
      icon: tab.iconName,
      minItems: 0,
    };

    if (tab.name === "collections")
      return (
        <CollectionsCarousel collectionPages={tab.data} {...carouselProps} />
      );
    else return <AttributeCarousel pages={tab.data} {...carouselProps} />;
  };

  return tabsData.map(getTabRow);
};

export default memo(TabsMobile);

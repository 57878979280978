import {
  EVENT_NAME_EXPLORE_PAGE_CAROUSEL_SLIDE_CHANGE,
  trackAnalyticsEvent,
} from "src/utils/track";

export const trackSlideChange = (swiper, title) => {
  trackAnalyticsEvent(EVENT_NAME_EXPLORE_PAGE_CAROUSEL_SLIDE_CHANGE, {
    activeIndex: swiper.activeIndex,
    progress: swiper.progress,
    title: title,
  });
};

import { memo } from "react";

import { StyledActiveFilterChip } from "./styles";

const ActiveFilterChip = ({ label, value, refine }) => {
  return (
    <StyledActiveFilterChip
      onDelete={
        !!refine
          ? (e) => {
              e.stopPropagation();
              refine(value);
            }
          : undefined
      }
      onClick={(e) => {
        e.stopPropagation();
      }}
      label={label}
      color="primary"
      clickable={false}
    />
  );
};

export default memo(ActiveFilterChip);

import { memo } from "react";

import {
  AttributeCarousel,
  CollectionsCarousel,
} from "src/components/explore/carousels";
import TabPanel from "./TabPanel";

import { useStyles } from "./styles";

const TabsDesktop = ({ currentTabIndex, tabsData }) => {
  const classes = useStyles();

  const getTabPanel = (tab, index) => {
    if (tab.name === "collections")
      return (
        <TabPanel key={tab.name} index={index} currentIndex={currentTabIndex}>
          <CollectionsCarousel
            collectionPages={tab.data}
            minItems={0}
            disableVerticalGutter
            rowVariant="noBackground"
          />
        </TabPanel>
      );
    else
      return (
        <TabPanel key={tab.name} index={index} currentIndex={currentTabIndex}>
          <AttributeCarousel
            pages={tab.data}
            minItems={0}
            disableVerticalGutter
            rowVariant="noBackground"
          />
        </TabPanel>
      );
  };

  return (
    <div className={classes.tabPanelsContainer}>
      {tabsData.map(getTabPanel)}
    </div>
  );
};

export default memo(TabsDesktop);

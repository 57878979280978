import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",

    paddingBottom: theme.spacing(5),

    background:
      "linear-gradient(0.39deg, rgba(0, 0, 0, 0.1) 0.41%, rgba(0, 0, 0, 0) 99.73%), #F4F4F4",
  },
}));

export const SEARCH_MODE_KEY = "searchMode";

export const SEARCH_MODE_VALUE_DEPARTURES = "departures";
export const SEARCH_MODE_VALUE_PRODUCTS = "products";

export const SEARCH_MODE_VALUES = [
  SEARCH_MODE_VALUE_DEPARTURES,
  SEARCH_MODE_VALUE_PRODUCTS,
];

export const getSearchModeFromQueryParams = (
  queryParamsObj,
  searchModeKey = SEARCH_MODE_KEY,
  searchModeValuesRange = SEARCH_MODE_VALUES,
  defaultValue = SEARCH_MODE_VALUE_PRODUCTS
) => {
  if (
    queryParamsObj &&
    queryParamsObj[searchModeKey] &&
    searchModeValuesRange.includes(queryParamsObj[searchModeKey])
  ) {
    return queryParamsObj[searchModeKey];
  }

  return defaultValue;
};

export const getSearchModeFromURLSearchParams = (
  searchParams,
  searchModeKey = SEARCH_MODE_KEY,
  searchModeValuesRange = SEARCH_MODE_VALUES,
  defaultValue = SEARCH_MODE_VALUE_PRODUCTS
) => {
  if (
    searchParams &&
    searchParams.has(searchModeKey) &&
    searchModeValuesRange.includes(searchParams.get(searchModeKey))
  ) {
    return searchParams.get(searchModeKey);
  }

  return defaultValue;
};

export const addSearchModeQueryParam = (
  urlSearchParams,
  searchMode,
  searchModeKey = SEARCH_MODE_KEY,
  defaultSearchMode = SEARCH_MODE_VALUE_PRODUCTS,
  searchModeValuesRange = SEARCH_MODE_VALUES
) => {
  if (!searchModeValuesRange.includes(searchMode)) return urlSearchParams;

  if (searchMode === defaultSearchMode) {
    urlSearchParams.delete(searchModeKey);
    return urlSearchParams;
  }

  urlSearchParams.set(searchModeKey, searchMode);
  return urlSearchParams;
};

import { Router } from "next/router";
import { useEffect, useState } from "react";

export const useLoadingState = () => {
  const [isReady, setIsReady] = useState(true);

  const routeChangeStart = (_, { shallow }) => {
    if (!shallow) {
      setIsReady(false);
      window.scrollTo(0, 0);
    }
  };

  const routeChangeEnd = () => {
    setIsReady(true);
  };

  useEffect(() => {
    Router.events.on("routeChangeStart", routeChangeStart);
    Router.events.on("routeChangeComplete", routeChangeEnd);
    Router.events.on("routeChangeError", routeChangeEnd);
    return () => {
      Router.events.off("routeChangeStart", routeChangeStart);
      Router.events.off("routeChangeComplete", routeChangeEnd);
      Router.events.off("routeChangeError", routeChangeEnd);
    };
  }, []);

  return isReady;
};

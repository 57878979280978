import useIsMdUp from "src/hooks/useIsMdUp";

import GutterContext from "./context";

function GutterProvider({ gutterSize = 2, children }) {
  const isMdUp = useIsMdUp();
  const value = {
    hasGutter: isMdUp,
    gutterSize,
  };
  return (
    <GutterContext.Provider value={value}>{children}</GutterContext.Provider>
  );
}

export default GutterProvider;

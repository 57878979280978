import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  icon: {
    margin: "6px",

    [theme.breakpoints.up("sm")]: {
      marginLeft: "0px",
    },
  },
  label: {
    margin: "6px",
    marginRight: "0px",
  },
}));

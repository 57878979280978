import { useMemo } from "react";

export const useBreadcrumbPaths = (currentPath) => {
  return useMemo(
    () =>
      currentPath
        .filter((path) => path.slug)
        .reduce(
          (paths, path) => [
            ...paths,
            {
              url: `${paths[paths.length - 1].url}${path.slug}/`,
              label: path.value,
            },
          ],
          [{ url: "/explore/", label: "Explore" }]
        ),
    [currentPath]
  );
};

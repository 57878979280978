import { withStyles, Chip } from "@material-ui/core";

export const StyledActiveFilterChip = withStyles((theme) => ({
  root: {
    marginRight: "5px",
    marginBottom: "5px",
  },
  deleteIconColorPrimary: {
    color: theme.palette.common.white,

    "&:hover": {
      color: theme.palette.common.white,
      opacity: 0.6,
    },
  },
}))(Chip);

export const StyledClearAllChip = withStyles((theme) => ({
  root: {
    marginRight: "5px",
    marginBottom: "5px",
    backgroundColor: theme.palette.grey[100],
  },
}))(Chip);

import React, { memo } from "react";

import { Grid, Typography } from "@material-ui/core";

import AttributeContent from "./AttributeContent";

const FilterAccordionPanelContents = ({ section }) => {
  return (
    <Grid container spacing={2}>
      {section.filters.map(
        ({
          title,
          Component,
          attribute,
          transformLabel = null,
          attributeContent = null,
          ComponentProps = { limit: 5, showMore: true, showMoreLimit: 200 },
        }) => {
          return (
            <Grid item xs={12} key={attribute}>
              {title && <Typography variant="h4">{title}</Typography>}

              {attributeContent && (
                <AttributeContent attributeContent={attributeContent} />
              )}

              <Component
                attribute={attribute}
                {...ComponentProps}
                transformLabel={transformLabel}
              />
            </Grid>
          );
        }
      )}
    </Grid>
  );
};

export default memo(FilterAccordionPanelContents);

import clsx from "clsx";
import React from "react";
import { ImageGallery } from "src/components/shared";
import {
  EVENT_NAME_PRODUCT_CARD_IMAGE_CAROUSEL,
  trackAnalyticsEvent,
} from "src/utils/track";

import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import grey from "@material-ui/core/colors/grey";
import { withStyles } from "@material-ui/core/styles";
import { getColorFromPalette } from "@muchbetteradventures/components/dist/Icons/colors";

import { Photograph } from "../Image";
import ShortlistProduct from "../Shortlist/ShortlistProduct";
import UserPrice from "../UserPrice";
import ProductAttribute from "./ProductAttribute";

const styles = (theme) => ({
  card: {
    borderStyle: "solid",
    borderColor: grey[200],
    border: 0,
    position: "relative",
    height: "100%",
    borderRadius: theme.spacing(2),
    "&:hover .show-on-hover": {
      opacity: 0.5,
    },
  },
  cardActionArea: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    flexGrow: 0,
  },
  staticImage: {
    height: 0,
    paddingTop: `${100 / 1.4}%`,
  },
  imageGallery: {
    height: 0,
    paddingTop: `${100 / 1.25}%`,
  },
  imageGalleryContainer: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
  },
  focusHighlight: {
    display: "none",
  },
  cardContent: {
    color: grey[700],
    padding: theme.spacing(2),
    flexGrow: 1,
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    borderRadius: `0 0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    borderWidth: "0 1px 1px 1px",
    borderStyle: "solid",
    borderColor: grey[300],
  },
  cardContentWithGallery: {
    paddingTop: theme.spacing(3), // to offset negative margin in mediaContainer
  },
  title: {
    flexGrow: 1,
  },
  cardContentColumn: {
    marginTop: "auto",
  },
  shortlistButton: {
    position: "absolute",
    top: theme.spacing(),
    right: theme.spacing(),
    color: "white",
    zIndex: 2,
  },
});

const durationsOutputString = (durations) =>
  `${durations.join(" or ")} Night${
    durations[0] === 1 && durations.length === 1 ? "" : "s"
  }`;

const ProductCard = ({
  classes,
  productId,
  showShortlistButton = false,
  showGallery = false,
  countries,
  currency,
  durations,
  images,
  imageSizes,
  price,
  reviewCount,
  reviewScore,
  title,
  url,
  onClick,
  children = null,
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Card className={classes.card} elevation={0}>
      {showShortlistButton && (
        <ShortlistProduct
          productId={productId}
          className={classes.shortlistButton}
          notShortlistedColor="white"
        />
      )}
      <CardActionArea
        disableRipple
        onClick={onClick}
        href={url}
        target="_blank"
        classes={{
          root: classes.cardActionArea,
          focusHighlight: classes.focusHighlight,
        }}
      >
        <div
          className={clsx(classes.imageContainer, {
            [classes.imageGallery]: showGallery,
            [classes.staticImage]: !showGallery,
          })}
        >
          {showGallery ? (
            // we need an additional div to set a height as swiper js doesn't like
            // the paddingTop trick for setting an aspect ratio
            <div className={classes.imageGalleryContainer}>
              <ImageGallery
                images={images}
                imageSizes={imageSizes}
                onSlideChange={(swiper) => {
                  trackAnalyticsEvent(EVENT_NAME_PRODUCT_CARD_IMAGE_CAROUSEL, {
                    activeIndex: swiper.activeIndex,
                    progress: swiper.progress,
                    productId: productId,
                  });
                }}
                showButtons={isMd && images.length > 1}
                allowTouchMove={!isMd}
              />
            </div>
          ) : (
            <Photograph alt="" src={`${images[0].url}`} sizes={imageSizes} />
          )}
        </div>

        <CardContent
          className={clsx(classes.cardContent, {
            [classes.cardContentWithGallery]: showGallery,
          })}
        >
          <Typography
            className={classes.title}
            color="textPrimary"
            variant="h3"
            gutterBottom
            component="div"
          >
            {title}
          </Typography>
          <Box display="flex" flexWrap="nowrap">
            <Box className={classes.cardContentColumn} flexGrow={1}>
              {countries.length > 0 && (
                <ProductAttribute
                  icon={"MapPin"}
                  value={countries.join(" \u00b7 ")}
                />
              )}
              <ProductAttribute
                icon={"StarFilled"}
                iconColor={getColorFromPalette("gold")}
                value={
                  reviewCount > 0
                    ? `${reviewScore.toFixed(2)} (${reviewCount} Review${
                        reviewCount !== 1 ? "s" : ""
                      })`
                    : "New"
                }
              />
            </Box>
            <Box className={classes.cardContentColumn} flexGrow={0}>
              <ProductAttribute
                value={durationsOutputString(durations)}
                alignment="right"
              />
              <Typography
                color="textPrimary"
                component="div"
                variant="h3"
                align="right"
                noWrap
              >
                {"FROM "}
                <UserPrice value={price} currencyCode={currency} round />
              </Typography>
            </Box>
          </Box>
          {children}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default withStyles(styles)(ProductCard);

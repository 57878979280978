import useGutter from "src/context/gutter/use";

import { Container as MuiContainer } from "@material-ui/core";

const Container = ({ ...props }) => {
  const { hasGutters } = useGutter();
  return <MuiContainer maxWidth="md" disableGutters={!hasGutters} {...props} />;
};

export default Container;

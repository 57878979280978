import { createContext, useContext } from "react";

const SearchContext = createContext({});

export const SearchContextProvider = ({
  searchMode,
  setSearchMode,
  index,
  children,
}) => (
  <SearchContext.Provider value={{ searchMode, setSearchMode, index }}>
    {children}
  </SearchContext.Provider>
);

export const useSearchContext = () => {
  const searchContext = useContext(SearchContext);

  return searchContext;
};

import React from "react";
import { connectRefinementList } from "react-instantsearch-dom";

import { MultiSelect } from "@muchbetteradventures/styleguide";

import { trackRefineFilter } from "../../tracking";

const MultiSelectRefinementList = connectRefinementList(
  ({ attribute, items, refine, icon, placeholder, colorVariant }) => {
    // Translate algolia items into options for our Multi Select
    // Since algolia's item value is an array that changes based on the other selected fields
    // it makes more sense to use the label which is a string that doesnt change
    const algoliaItemToSelectOptionValue = (item) => item.label;

    const selectOptionValuetoAlgoliaItemValue = (value) =>
      items.find((item) => item.label === value).value;

    const selectOptions = items.reduce((acc, item) => {
      const value = algoliaItemToSelectOptionValue(item);
      acc[value] = {
        name: item.label,
        subtext: ` [${item.count}]`,
      };
      return acc;
    }, {});

    const selectedValues = items.reduce((acc, item) => {
      const value = algoliaItemToSelectOptionValue(item);
      if (item.isRefined) acc.push(value);
      return acc;
    }, []);

    // Our Multi Select gives us the new list of selected values,
    // but refine expects to be called as a toggle,
    // so we need to calculate the difference
    //
    // NOTE: This only works if ONLY ONE value has changed
    //       since algolia doesnt allow us to call refine in quick succession
    const handleChange = (newValues) => {
      const addedValues = newValues.filter((x) => !selectedValues.includes(x));
      addedValues.forEach((value) => {
        trackRefineFilter(attribute);
        refine(selectOptionValuetoAlgoliaItemValue(value));
      });

      const removedValues = selectedValues.filter(
        (x) => !newValues.includes(x)
      );
      removedValues.forEach((value) => {
        trackRefineFilter(attribute);
        refine(selectOptionValuetoAlgoliaItemValue(value));
      });
    };

    return (
      <MultiSelect
        colorVariant={colorVariant}
        icon={icon}
        onChange={handleChange}
        options={selectOptions}
        placeholder={placeholder}
        values={selectedValues}
      />
    );
  }
);

export default MultiSelectRefinementList;

import React, { useContext, useEffect, useState } from "react";
import { connectRange } from "react-instantsearch-dom";
import { AccordionPanelContext } from "src/components/Accordion/Panel";

import { TextField, Typography } from "@material-ui/core";

import { trackRefineFilter } from "../../../tracking";

// WARNING: This component expects integers and will not work with floats
// For floats, use a slider or a number field

export const MinMaxDropdowns = ({
  attribute,
  currentRefinement: { min: currentMin, max: currentMax },
  min,
  max,
  refine,
  label,
  minLabel = "Min",
  maxLabel = "Max",
}) => {
  const [localMin, setLocalMin] = useState(currentMin ?? min);
  const [localMax, setLocalMax] = useState(currentMax ?? max);

  // If props update, make sure we're prioritising those over local state,
  useEffect(() => {
    setLocalMin(currentMin);
    setLocalMax(currentMax);
  }, [currentMin, currentMax]);

  const { hideSelf, showSelf } = useContext(AccordionPanelContext);
  useEffect(() => {
    if (min < max) !!showSelf && showSelf();
    else !!hideSelf && hideSelf();
  }, [min, max, showSelf, hideSelf]);

  const handleMinChange = (event) => {
    let newMin = parseInt(event.currentTarget.value);
    if (newMin < min) newMin = min;

    const range = {
      min: newMin,
      max: currentMax,
    };
    refine(range);
    setLocalMin(newMin);
    trackRefineFilter(attribute, range);
  };

  const handleMaxChange = (event) => {
    const newMax = parseInt(event.currentTarget.value);
    if (newMax > max) newMax = max;

    const range = {
      min: currentMin,
      max: newMax,
    };
    refine(range);
    setLocalMax(newMax);
    trackRefineFilter(attribute, range);
  };

  const minOptions = range(min, Math.min(max, localMax));

  const maxOptions = range(Math.max(min, localMin), max);

  return (
    <>
      {label && <Typography variant="body1">{label}</Typography>}
      <TextField
        margin="normal"
        select
        value={localMin}
        onChange={handleMinChange}
        SelectProps={{
          native: true,
        }}
        fullWidth
        label={minLabel}
        variant="outlined"
      >
        {minOptions.map((minValue) => (
          <option key={`min_${minValue}`} value={minValue}>
            {minValue}
          </option>
        ))}
      </TextField>

      <TextField
        margin="normal"
        select
        value={localMax}
        onChange={handleMaxChange}
        SelectProps={{
          native: true,
        }}
        fullWidth
        label={maxLabel}
        variant="outlined"
      >
        {maxOptions.map((maxValue) => (
          <option key={`min_${maxValue}`} value={maxValue}>
            {maxValue}
          </option>
        ))}
      </TextField>
    </>
  );
};

export default connectRange(MinMaxDropdowns);

const range = (start, end, length = end - start + 1) =>
  Array.from({ length }, (_, i) => start + i);

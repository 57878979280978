import { memo } from "react";
import dynamic from "next/dynamic";

import Section from "src/components/Page/Section";
import {
  AttributeCarousel,
  CollectionsCarousel,
  MagazineArticleCarousel,
} from "src/components/explore/carousels";
import GridRow from "src/components/explore/GridRow";
import SalesPoints from "src/components/explore/SalesPoints";
import Loader from "src/components/explore/Search/components/Loader";

const CollectionCarousel = dynamic(
  () => import("src/components/explore/carousels/CollectionCarousel"),
  {
    loading: Loader,
  }
);

const PageRows = ({ rows, currentPath, childPages, collectionPages }) => {
  const rowTypes = {
    AttributeCarousel: ({ value, ...rest }) => (
      <AttributeCarousel
        pages={childPages.filter((page) => page.attribute === value)}
        currentPath={currentPath}
        {...rest}
      />
    ),

    CollectionCarousel: ({ value, ...rest }) => (
      <CollectionCarousel name={value} {...rest} />
    ),

    CollectionsCarousel: ({ value, ...rest }) => (
      <CollectionsCarousel
        collectionPages={value
          .map((collectionName) =>
            collectionPages.find(
              (collectionPage) => collectionPage.value === collectionName
            )
          )
          .filter((page) => Boolean(page))}
        {...rest}
      />
    ),

    GridRow: ({ value, ...rest }) => (
      <GridRow
        collections={value.collectionNames
          .map((collectionName) =>
            collectionPages.find(
              (collectionPage) => collectionPage.value === collectionName
            )
          )
          .filter((item) => item !== undefined)}
        promo={value.promo}
        {...rest}
      />
    ),

    MagazineArticleCarousel: ({ value, ...rest }) => (
      <MagazineArticleCarousel magazineArticles={value} {...rest} />
    ),

    SalesPoints: ({ value, ...rest }) => (
      <SalesPoints salesPoints={value} {...rest} />
    ),

    PageSection: ({ value }) => <Section {...value} />,
  };

  const componentForRow = ({ type, ...rest }) =>
    rowTypes[type]?.({ ...rest }) ?? null;

  return rows.map(componentForRow);
};

export default memo(PageRows);

import FilterAccordionPanelContents from "./FilterAccordionPanelContents";
import { SectionActiveFilters } from "./ActiveFilters";

const makeFilterAccordionPanels = (sections, styleOverrides) => {
  return sections.map((section) => ({
    title: section.title,
    icon: section.icon,
    styleOverrides,
    mountOnEnter: false,
    contents: <FilterAccordionPanelContents section={section} />,
    panelToggleContent: <SectionActiveFilters section={section} />,
  }));
};

export default makeFilterAccordionPanels;

import { useCallback, useEffect } from "react";
import { SEARCH_MODE_VALUE_DEPARTURES } from "src/search";
import { setAlgoliaQueryContext } from "src/utils/algoliaQueryContext";
import {
  EVENT_NAME_CLICK_VIEW_ALL_TRIPS_BUTTON,
  EVENT_NAME_PRODUCT_CARD_IMAGE_CAROUSEL,
  EVENT_NAME_PRODUCT_CLICKED,
  EVENT_NAME_REFINE_EXPLORE_PAGE_FILTER,
  EVENT_NAME_RESET_EXPLORE_PAGE_FILTERS,
  EVENT_NAME_SEARCH_FILTER_CHANGED,
  EVENT_NAME_VIEW_DEPARTURES_SEARCH_MODE,
  trackAnalyticsEvent,
} from "src/utils/track";

export const trackSearchStateChange = (newState) => {
  trackAnalyticsEvent(EVENT_NAME_SEARCH_FILTER_CHANGED, { ...newState });
};

export const trackRefineFilter = (attribute, value) => {
  trackAnalyticsEvent(EVENT_NAME_REFINE_EXPLORE_PAGE_FILTER, {
    attribute,
    value,
  });
};

export const trackResetFilters = () => {
  trackAnalyticsEvent(EVENT_NAME_RESET_EXPLORE_PAGE_FILTERS, {});
};

export const useTrackDeparturesSearchMode = (searchMode) => {
  useEffect(() => {
    // in an effect to make sure it runs on page load, not only when it changes
    if (searchMode === SEARCH_MODE_VALUE_DEPARTURES) {
      trackAnalyticsEvent(EVENT_NAME_VIEW_DEPARTURES_SEARCH_MODE, {});
    }
  }, [searchMode]);
};

export const useTrackProductClick = (hit, isShortlisted, searchMode, index) =>
  useCallback(() => {
    const pathname = document.location.pathname;

    const params = {
      list: `search__${pathname}`,
      searchMode,

      ...hitToTrackingObject(hit),

      // Algolia Specific
      index,
      eventType: "click",
      queryID: hit.__queryID,
      objectID: hit.objectID,
    };

    setAlgoliaQueryContext(
      params.index,
      params.queryID,
      params.objectID,
      isShortlisted
    );

    trackAnalyticsEvent(EVENT_NAME_PRODUCT_CLICKED, params);
  }, [hit, isShortlisted, searchMode, index]);

export const useTrackViewAllTripsButton = () =>
  useCallback(() => {
    trackAnalyticsEvent(EVENT_NAME_CLICK_VIEW_ALL_TRIPS_BUTTON, {});
  }, []);

export const useTrackSlideChange = (productId) =>
  useCallback(
    (activeIndex, progress) => {
      trackAnalyticsEvent(EVENT_NAME_PRODUCT_CARD_IMAGE_CAROUSEL, {
        activeIndex,
        progress,
        productId,
      });
    },
    [productId]
  );

export const hitToTrackingObject = (hit) => ({
  product_id: `${hit.productID}`,
  category: "Adventures",
  name: hit.name,
  brand: hit.companyName,
  url: `${document.location.protocol}//${document.location.host}${hit.url}`,
  position: hit.__position,
});

export const hitsToTrackingObject = (hits, algoliaIndex, algoliaEventType) => ({
  index: algoliaIndex,
  eventType: algoliaEventType,
  queryID: hits[0]?.__queryID,
  objectIDs: hits.map((hit) => hit.objectID),
  positions: hits.map((hit) => hit.__position),
  products: hits.map(hitToTrackingObject),
});

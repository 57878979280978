import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
  createStyles({
    gridRoot: {
      position: "relative",
      display: "grid",
      gridGap: theme.spacing(2),
      gridTemplateRows: "auto",
      [theme.breakpoints.down("xs")]: {
        ["& > *:first-child"]: {
          marginLeft: -theme.spacing(2),
          marginRight: -theme.spacing(2),
        },
      },
    },
    gridRoot1: {
      gridTemplateColumns: "1fr",
      gridTemplateAreas: `
        "item0"
      `,
      ["& > *"]: {
        paddingTop: "40% !important",
      },
      [theme.breakpoints.down("xs")]: {
        ["& > *"]: {
          paddingTop: "100% !important",
        },
      },
    },
    gridRoot2: {
      gridTemplateColumns: "2fr 1fr",
      gridTemplateAreas: `
        "item0 item1"
      `,
      ["& > *"]: {
        paddingTop: "66.67% !important",
      },
      [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: "1fr",
        gridTemplateAreas: `
        "item0"
        "item1"`,
        ["& > *"]: {
          paddingTop: "100% !important",
        },
        ["& > *:last-child"]: {
          paddingTop: "50% !important",
        },
      },
    },
    gridRoot3: {
      gridTemplateColumns: "1fr 1fr",
      gridTemplateAreas: `
        "item0 item1"
        "item0 item2"`,
      ["& > *"]: {
        paddingTop: "50% !important",
      },
      [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: "1fr 1fr",
        gridTemplateAreas: `
        "item0 item0"
        "item1 item2"`,
        ["& > *"]: {
          paddingTop: "120% !important",
        },
        ["& > *:first-child"]: {
          paddingTop: "100% !important",
        },
      },
    },
    gridRoot4: {
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
      gridTemplateAreas: `
        "item0 item0 item1 item2"
        "item0 item0 item3 item3"`,
      ["& > *:last-child"]: {
        paddingTop: "50%",
      },
      [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: "1fr 1fr",
        gridTemplateAreas: `
        "item0 item0"
        "item1 item2"
        "item3 item3"
        `,
        ["& > *:last-child"]: {
          paddingTop: "50% !important",
        },
        ["& > *"]: {
          paddingTop: "120% !important",
        },
        ["& > *:first-child"]: {
          paddingTop: "100% !important",
        },
      },
    },
    gridRoot5: {
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
      gridTemplateAreas: `
        "item0 item0 item1 item2"
        "item0 item0 item3 item4"`,
      ["& > *:last-child"]: {
        paddingTop: "50%",
      },
      [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: "1fr 1fr",
        gridTemplateAreas: `
        "item0 item0"
        "item1 item2"
        "item3 item4"
        `,
        ["& > *:last-child"]: {
          paddingTop: "50% !important",
        },
        ["& > *"]: {
          paddingTop: "120% !important",
        },
        ["& > *:first-child"]: {
          paddingTop: "100% !important",
        },
      },
    },
    gridItem: {
      position: "relative",
      paddingTop: "100%",
    },
    gridItem0: {
      gridArea: "item0",
    },
    gridItem1: {
      gridArea: "item1",
    },
    gridItem2: {
      gridArea: "item2",
    },
    gridItem3: {
      gridArea: "item3",
    },
    gridItem4: {
      gridArea: "item4",
    },
    disableTopGutterMobile: {
      [theme.breakpoints.down("xs")]: {
        paddingTop: "0px",
      },
    },
    disableBottomGutterMobile: {
      [theme.breakpoints.down("xs")]: {
        paddingBottom: "0px",
      },
    },
  })
);

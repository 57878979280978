import clsx from "clsx";
import Link from "next/link";
import { Photograph } from "src/components/Image";
import pluralize from "src/utils/pluralize";

import {
  Box,
  Card,
  CardActionArea,
  Chip,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      borderRadius: theme.spacing(2),
      border: 0,
      overflow: "hidden",
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    cover: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      top: 0,
    },
    overlay: {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      padding: theme.spacing(2),
    },
    titleOverlay: {
      color: "white",
      flexDirection: "column",
      justifyContent: "center",
      background:
        "linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%)",
    },
    tripHitsChip: {
      fontWeight: "bold",
      textTransform: "uppercase",
      fontSize: 10,
    },
    tripHitsChipOverlay: {
      pointerEvents: "none",
      flexDirection: "column-reverse"
    },
  })
);

const LinkCard = ({ page, href, onClick, imageSizes }) => {
  const classes = useStyles();
  const title = page.title || page.value;
  
  return (
    <Card className={classes.root} variant="outlined">
      <Link href={href} passHref>
        <CardActionArea
          className={classes.cover}
          title={title}
          onClick={onClick}
        >
          <Box className={classes.cover}>
            {Boolean(page.image) && (
              <Photograph
                src={page.image.url}
                alt=""
                sizes={imageSizes}
                shouldShowPlaceholder={false}
              />
            )}
          </Box>
          <Box className={clsx(classes.cover, classes.overlay, classes.titleOverlay)}>
            <Typography variant="body2">
              <strong>{page.value}</strong>
            </Typography>
          </Box>
        </CardActionArea>
      </Link>
      <Box className={clsx(classes.cover, classes.overlay, classes.tripHitsChipOverlay)}>
          <Chip
            variant="default"
            size="small"
            className={classes.tripHitsChip}
            label={`${page.hits} Trip${pluralize(page.hits)}`}
          />
      </Box>
    </Card>
  );
};

export default LinkCard;

import React from "react";
import { connectRange } from "react-instantsearch-dom";
import { SEARCH_MODE_VALUE_DEPARTURES } from "src/search";
import * as date from "src/utils/date";
import groupBy from "src/utils/groupBy";

import { TextField, Typography } from "@material-ui/core";

import { useSearchContext } from "../../../context";
import { trackRefineFilter } from "../../../tracking";

const dayOfMonth = (date, monthIncrement = 0, day = 1) =>
  new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth() + monthIncrement,
      day,
      0,
      0,
      0,
      0
    )
  );

const calcMonthSelected = (dateAsTimestamp) =>
  dateAsTimestamp
    ? date.dateToEpoch(dayOfMonth(date.epochToDate(dateAsTimestamp)))
    : undefined;

export const DatePickerControl = ({
  attribute,
  currentRefinement,
  min,
  max,
  refine,
  showMinMaxDateFields = false,
  label,
}) => {
  // console.log(
  //   "currentRefinement:",
  //   date.epochToDate(currentRefinement.min),
  //   date.epochToDate(currentRefinement.max)
  // );

  const { searchMode } = useSearchContext();
  const openEnded = searchMode === SEARCH_MODE_VALUE_DEPARTURES;

  const isMinRefined = currentRefinement.min !== min;
  const isMaxRefined = currentRefinement.max !== max;

  const [monthSelected, setMonthSelected] = React.useState(
    isMinRefined ? calcMonthSelected(currentRefinement.min) : ""
  );

  const [singleDateSelected, setSingleDateSelected] = React.useState(
    currentRefinement.min === currentRefinement.max ? currentRefinement.min : ""
  );

  const [minDateSelected, setMinDateSelected] = React.useState(
    isMinRefined ? currentRefinement.min : ""
  );

  const [maxDateSelected, setMaxDateSelected] = React.useState(
    isMaxRefined ? currentRefinement.max : ""
  );

  // React.useEffect(() => {
  //   // Ensure we re-refine our dates IF the curent refinement
  //   // is outside the allowed range. Only refine IF the new values are
  //   // different to current.

  //   console.log(currentRefinement);

  //   const newMin = Math.min(max, Math.max(min, currentRefinement.min));
  //   const newMax = Math.max(min, Math.min(max, currentRefinement.max));
  //   if (newMin !== currentRefinement.min || newMax !== currentRefinement.max) {
  //     refine({
  //       min: newMin,
  //       max: newMax,
  //     });
  //   }
  // }, [currentRefinement.min, currentRefinement.max, min, max, refine]);

  React.useEffect(() => {
    setMonthSelected(
      isMinRefined ? calcMonthSelected(currentRefinement.min) : ""
    );

    setSingleDateSelected(
      currentRefinement.min === currentRefinement.max || openEnded
        ? currentRefinement.min
        : ""
    );
    setMinDateSelected(isMinRefined ? currentRefinement.min : "");
    setMaxDateSelected(isMaxRefined ? currentRefinement.max : "");
  }, [currentRefinement, isMinRefined, isMaxRefined, openEnded]);

  const monthSelectOptions = React.useMemo(() => {
    if (min && max) {
      const monthOptions = [];
      let counter = 0;
      const minAsDate = date.epochToDate(min);
      const maxAsDate = date.epochToDate(max);

      let currentMonth = dayOfMonth(minAsDate, counter);

      while (currentMonth < maxAsDate) {
        monthOptions.push({
          year: parseInt(currentMonth.getFullYear(), 10),
          value: date.dateToEpoch(currentMonth),
          label: `${currentMonth.toLocaleString("default", {
            month: "long",
          })} ${currentMonth.getFullYear()}`,
        });
        counter++;
        currentMonth = dayOfMonth(minAsDate, counter);
      }
      return groupBy(monthOptions, "year");
    } else {
      return {};
    }
  }, [min, max]);

  const minDateSelectOptions = React.useMemo(() => {
    if (monthSelected) {
      const dateOptions = [];
      let counter = 1;
      let currentDate = dayOfMonth(date.epochToDate(monthSelected));
      const maxAsDate = dayOfMonth(currentDate, 1);
      while (currentDate < maxAsDate) {
        const value = date.dateToEpoch(currentDate);
        dateOptions.push({
          disabled: value < min || value > max,
          value,
          label: `${currentDate.toLocaleString("default", {
            month: "long",
            weekday: "short",
            day: "numeric",
          })}`,
        });
        counter++;
        currentDate = dayOfMonth(currentDate, 0, counter);
      }
      return dateOptions;
    } else {
      return [];
    }
  }, [monthSelected, min, max]);

  const maxDateSelectOptions = React.useMemo(() => {
    if (monthSelected) {
      const dateOptions = [];
      let counter = 1;
      let currentDate = dayOfMonth(date.epochToDate(monthSelected));
      const maxAsDate = dayOfMonth(currentDate, 1);
      while (currentDate < maxAsDate) {
        const value = date.dateToEpoch(currentDate);
        dateOptions.push({
          disabled: value < minDateSelected || value > max,
          value,
          label: `${currentDate.toLocaleString("default", {
            month: "long",
            weekday: "short",
            day: "numeric",
          })} ${currentDate.getFullYear()}`,
        });
        counter++;
        currentDate = dayOfMonth(currentDate, 0, counter);
      }
      return dateOptions;
    } else {
      return [];
    }
  }, [monthSelected, minDateSelected, max]);

  const selectDate = (newMin, newMax) => {
    const selectedMin = newMin
      ? Math.max(min, parseInt(newMin, 10))
      : undefined;

    const selectedMax = openEnded
      ? undefined
      : newMax
      ? Math.min(max, parseInt(newMax, 10))
      : undefined;

    setMinDateSelected(selectedMin);
    setMaxDateSelected(selectedMax);

    // console.log(
    //   "Selecting:",
    //   date.epochToDate(selectedMin),
    //   date.epochToDate(selectedMax)
    // );

    // We use this timeout so that the UI updates before `refine` is called
    // which causes a bit of a stall
    setTimeout(() => {
      refine({
        min: selectedMin,
        max: selectedMax,
      });
      trackRefineFilter(attribute, {
        min: selectedMin,
        max: selectedMax,
      });
    }, 50);
  };

  const getMaxValueFromMonthSelected = (value) => {
    return Math.min(
      max,
      date.dateToEpoch(dayOfMonth(date.epochToDate(value), 1, 0))
    );
  };

  const handleMonthSelectChange = (e) => {
    const { value } = e.target;
    setMonthSelected(value);
    const maxValue = value ? getMaxValueFromMonthSelected(value) : undefined;
    selectDate(value, maxValue);
  };

  const handleMinDateSelectChange = (e) => {
    const { value } = e.target;
    selectDate(value, Math.max(value, currentRefinement.max));
  };

  const handleMaxDateSelectChange = (e) => {
    const { value } = e.target;
    selectDate(Math.min(currentRefinement.min, value), value);
  };

  const handleSingleDateSelectChange = (e) => {
    const { value } = e.target;
    if (value) {
      selectDate(value, value);
    } else {
      selectDate(monthSelected, getMaxValueFromMonthSelected(monthSelected));
    }
    setSingleDateSelected(value);
  };

  return (
    <>
      {label && <Typography variant="body1">{label}</Typography>}
      <TextField
        margin="normal"
        select
        value={monthSelected}
        onChange={handleMonthSelectChange}
        SelectProps={{
          native: true,
        }}
        fullWidth
        label={"Month"}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      >
        <option value="">{monthSelected ? "Any Month" : "Select Month"}</option>
        {Object.entries(monthSelectOptions).map(([year, months]) => (
          <optgroup key={year} label={year}>
            {months.map((month) => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))}
          </optgroup>
        ))}
      </TextField>
      <TextField
        margin="normal"
        select
        disabled={!Boolean(monthSelected)}
        value={singleDateSelected}
        onChange={handleSingleDateSelectChange}
        SelectProps={{
          native: true,
        }}
        fullWidth
        variant="outlined"
        label="Day"
        InputLabelProps={{ shrink: true }}
      >
        {!openEnded && (
          <option value="">{monthSelected ? "Any Day" : "Select Day"}</option>
        )}
        {minDateSelectOptions.map((day) => (
          <option key={day.value} value={day.value} disabled={day.disabled}>
            {day.label}
          </option>
        ))}
      </TextField>

      {showMinMaxDateFields && (
        <>
          <TextField
            margin="normal"
            select
            disabled={!Boolean(monthSelected)}
            value={minDateSelected}
            onChange={handleMinDateSelectChange}
            SelectProps={{
              native: true,
            }}
            fullWidth
            variant="outlined"
            label="Day"
            InputLabelProps={{ shrink: Boolean(minDateSelectOptions.length) }}
          >
            <option />
            {minDateSelectOptions.map((day) => (
              <option key={day.value} value={day.value} disabled={day.disabled}>
                {day.label}
              </option>
            ))}
          </TextField>
          <TextField
            margin="normal"
            select
            disabled={!Boolean(monthSelected)}
            value={maxDateSelected}
            onChange={handleMaxDateSelectChange}
            SelectProps={{
              native: true,
            }}
            fullWidth
            variant="outlined"
            label="Day"
            InputLabelProps={{ shrink: Boolean(maxDateSelectOptions.length) }}
          >
            <option />
            {maxDateSelectOptions.map((day) => (
              <option key={day.value} value={day.value} disabled={day.disabled}>
                {day.label}
              </option>
            ))}
          </TextField>
        </>
      )}
    </>
  );
};

export default connectRange(DatePickerControl);

import {
  EVENT_NAME_CLICK_CAROUSEL_ITEM,
  trackAnalyticsEvent,
} from "src/utils/track";

export const trackClick = (index, magazineArticle) => {
  trackAnalyticsEvent(EVENT_NAME_CLICK_CAROUSEL_ITEM, {
    carouselIndex: index,
    type: "magazine",
    ...magazineArticle,
  });
};

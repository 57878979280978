import React from "react";

import { Box, Typography } from "@material-ui/core";

const RangeSliderValueLabel = ({ children }) => (
  <Box bgcolor="rgba(255,255,255,0.8) !important" paddingX={0.5}>
    <Typography>{children}</Typography>
  </Box>
);

export default RangeSliderValueLabel;

import { memo, useCallback } from "react";

import FullWidthCarousel from "../shared/FullWidthCarousel";
import MagazineArticle from "./MagazineArticle";
import { useStyles } from "./styles";
import { trackClick } from "./tracking";

const MagazineArticles = ({ magazineArticles = [], ...rest }) => {
  const styles = useStyles();

  const makeHandleClick = useCallback(
    (index, magazineArticle) => () => trackClick(index, magazineArticle),
    []
  );

  return (
    <FullWidthCarousel
      slides={magazineArticles.filter(Boolean).map((magazineArticle, index) => (
        <MagazineArticle
          key={magazineArticle.title}
          magazineArticle={magazineArticle}
          index={index}
          onClick={makeHandleClick(index, magazineArticle)}
        />
      ))}
      slideClassName={styles.slide}
      {...rest}
    />
  );
};

export default memo(MagazineArticles);
